<template>
  <div :id="'lienholderBox' + index" class="lienholder">
    <v-dialog v-model="showModal">
      <div class="modalScroller">
        <div class="modalCloseButton">
          <v-btn @click="showModal = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <lienGrid
          :isModal="true"
          @select="setLienholdFromModal($event)"
          :nameSet="lienholder.lienholder.businessCustomer.businessName"
        >
        </lienGrid>
      </div>
    </v-dialog>
    <div
      :class="{
        unverified: true && !readOnly,
        verified: verified && !readOnly,
        subTable: true
      }"
    >
      <div>
        <v-btn
          v-show="!readOnly && lienholder.AddedLien"
          tabindex="-1"
          @click="removeLien(index)"
          class="close"
          icon
        >
          <v-icon color="error">close</v-icon>
        </v-btn>
        <div class="flex">
          <v-text-field
            label="Prec."
            type="text"
            :id="'precedence' + index"
            :readonly="readOnly"
            v-model="precedence"
            class="PrecedenceField"
          />
          <v-text-field
            v-show="!readOnly"
            :readonly="readOnly"
            v-model="lienholderLookupID"
            v-debounce:500="lienholderIDAutoComplete"
            type="number"
            @wheel="$event.target.blur()"
            min="0"
            label="ID"
            class="lienID"
          />
          <v-combobox
            class="nameField"
            auto-select-first
            :items="autoCompleteLienholders"
            hide-no-data
            hide-selected
            autocomplete="typeahead"
            :readOnly="readOnly"
            :disable-lookup="readOnly"
            :label="'Name'"
            :search-input.sync="nameSearch"
            v-model="lienholder.lienholder.businessCustomer.businessName"
            return-object
            @change="setLienholderFromTypeahead"
            @keydown.tab="handleTab"
          />

          <starDatePicker
            v-model="lienholder.lienDate"
            label="Start Date"
            :readonly="readOnly"
            :modelFormat="modelFormat"
            :displayFormat="BDPProps.displayFormat"
            :textFieldProps="BDPProps.TextField"
            :datePickerProps="BDPProps.DatePicker"
            :menuProps="BDPProps.Menu"
          />
          <starDatePicker
            v-if="!lienholder.AddedLien"
            v-model="lienholder.dischargeDate"
            label="Discharge Date"
            :readonly="readOnly"
            :modelFormat="modelFormat"
            :displayFormat="BDPProps.displayFormat"
            :textFieldProps="BDPProps.TextField"
            :datePickerProps="BDPProps.DatePicker"
            :menuProps="BDPProps.Menu"
          />
        </div>
        <div v-if="isWorking" id="loadingDiv">
          <h2>Loading...</h2>
        </div>
        <div v-show="showAddress || readOnly" class="flex">
          <vue-smarty-streets
            :uppercase="true"
            :readonly="isWorking || readOnly"
            :addressObj="lienholder.lienholder.physicalAddress"
          ></vue-smarty-streets>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vueSmartyStreets from "@/components/nonPageComponents/vue-smarty-streets";
import lienGrid from "@/components/nonPageComponents/lienholderGrid";
import starDatePicker from "@/components/nonPageComponents/starDatePicker";
import { mapGetters } from "vuex";

// mixins
import schemaValidation from "@/mixins/schemaValidation.mixin";
import date from "@/mixins/date.mixin";

export default {
  name: "Lienholder",
  props: {
    lienholder: { type: Object },
    index: { type: Number },
    processObj: { type: Object },
    readOnly: { type: Boolean, default: false }
  },
  mixins: [date, schemaValidation],
  components: {
    vueSmartyStreets,
    lienGrid,
    starDatePicker
  },
  data() {
    return {
      modelFormat: "YYYY-MM-DDTHH:mm:ssZ",
      lienholderLookupID: "",
      isWorking: false,
      showModal: false,
      autoCompleteLienholders: [],
      autoCompleteLienholdersObj: [],
      precedence: "",
      showAddress: true,
      nameSearch: ""
    };
  },
  methods: {
    handleTab(e) {
      if (
        this.autoCompleteLienholders[0] !==
        this.lienholder.lienholder.businessCustomer.businessName
      ) {
        e.preventDefault();
        const evt = new CustomEvent("keydown");
        evt.which = 13;
        evt.keyCode = 13;
        if (e.target !== undefined) {
          e.target.dispatchEvent(evt);
        }
      }
    },
    setLienholderFromTypeahead() {
      if (
        this.autoCompleteLienholdersObj[
          this.lienholder.lienholder.businessCustomer.businessName
        ] !== undefined
      ) {
        this.setLienholdFromModal(
          this.autoCompleteLienholdersObj[
            this.lienholder.lienholder.businessCustomer.businessName
          ]
        );
      }
    },

    setLienholdFromModal(value) {
      this.lienholderLookupID = value.accountNumber;
      this.lienholder.lienholder.businessCustomer.businessName =
        value.customerName;
      this.autoCompleteLienholders = [
        this.lienholder.lienholder.businessCustomer.businessName
      ];
      this.lienholder.lienholder.physicalAddress = value.address;
      this.showModal = false;
    },
    async lienholderIDAutoComplete() {
      if (this.lienholderLookupID === "") {
        return false;
      }
      this.isWorking = true;
      try {
        const response = await this.$api.searchLienByID(
          this.lienholderLookupID,
          {
            404: () => {}
          }
        );
        this.setLienholdFromModal(response);
        this.isWorking = false;
      } catch (e) {
        this.isWorking = false;
      }
    },
    removeLien(index) {
      if (
        this.processObj.liens[index] != undefined &&
        this.processObj.liens[index].AddedLien != undefined &&
        this.processObj.liens[index].AddedLien
      ) {
        this.processObj.liens.splice(index, 1);
      }
      for (let i = 0; i < this.processObj.liens.length; i++) {
        this.processObj.liens[i].lienPrecedenceNo = i + 1;
      }
      this.$forceUpdate();
    },
    sortLienholders(lienholders) {
      if (lienholders != undefined) {
        const sorted = lienholders.slice(0);
        sorted.sort(function (a, b) {
          const x = a.lienPrecedenceNo;
          const y = b.lienPrecedenceNo;
          return x < y ? -1 : x > y ? 1 : 0;
        });
        return sorted;
      }
    },
    checkField(field) {
      return field != undefined && field != "";
    }
  },
  computed: {
    ...mapGetters({
      BDPProps: "BDPProps",
      countyConfig: "countyConfig"
    }),
    verified() {
      let verified = true;
      if (
        this.lienholder.lienholder == undefined ||
        [null, undefined, ""].includes(
          this.lienholder.lienholder.businessCustomer
        ) ||
        !this.checkField(
          this.lienholder.lienholder.businessCustomer.businessName
        )
      ) {
        verified = false;
      }
      if (
        this.lienholder.AddedLien &&
        [null, undefined, ""].includes(this.lienholder.lienDate)
      ) {
        verified = false;
      }
      const address = this.lienholder.lienholder.physicalAddress;
      if (
        [null, undefined, ""].includes(address.address1) ||
        [null, undefined, ""].includes(address.city) ||
        [null, undefined, ""].includes(address.state) ||
        [null, undefined, ""].includes(address.postalCode)
      ) {
        verified = false;
      }
      return verified;
    }
  },
  created() {
    if (!this.readonly) {
      this.getValidationSchema(
        this.$store.getters.apiHostAndPort + "/lien.schema.json"
      ).then(json => {
        this.schemaJSON = json;
        this.loadSchemaValidator().then(() => {
          this.validateSchema(this.lienholder);
        });
      });
    }

    if (
      ![undefined, null, ""].includes(
        this.lienholder.lienholder.businessCustomer.businessName
      )
    ) {
      this.autoCompleteLienholders.push(
        this.lienholder.lienholder.businessCustomer.businessName
      );
    }
  },
  mounted() {
    this.precedence = this.lienholder.lienPrecedenceNo;
    this.tempTitle = this.processObj.title;
  },
  watch: {
    nameSearch(val) {
      if (
        this.readOnly ||
        val === this.lienholder.lienholder.businessCustomer.businessName
      ) {
        return;
      }

      this.autoCompleteLienholdersObj = [];
      this.autoCompleteLienholders = [];

      this.$api
        .searchLienHolders(this.$store.getters.countyId, val)
        .then(data => {
          if (data) {
            for (let i = 0; i < data.length && i < 20; i++) {
              this.autoCompleteLienholdersObj[data[i].customerName] = data[i];
              this.autoCompleteLienholders.push(data[i].customerName);
            }
          }
        });
    },
    precedence: function (newVal, oldVal) {
      if (newVal != "" && newVal != this.lienholder.lienPrecedenceNo) {
        this.lienholder.lienPrecedenceNo = newVal;
        const liens = this.processObj.liens;
        for (let i = 0; i < liens.length; i++) {
          if (
            newVal == liens[i].lienPrecedenceNo &&
            liens[i].lienholder.businessCustomer.businessName !=
              this.lienholder.lienholder.businessCustomer.businessName
          ) {
            if (oldVal == "") {
              liens[i].lienPrecedenceNo =
                parseInt(liens[liens.length - 1].lienPrecedenceNo) + 1;
            } else {
              liens[i].lienPrecedenceNo = oldVal;
            }
          }
        }
        this.processObj.liens = this.sortLienholders(liens);
        let focusIndex = "";
        for (let i = 0; i < this.processObj.liens.length; i++) {
          if (
            this.processObj.liens[i].lienholder.businessCustomer.businessName ==
            this.lienholder.lienholder.businessCustomer.businessName
          ) {
            focusIndex = i;
            window.scrollTo({
              behavior: "smooth",
              left: 0,
              top:
                document.getElementById("lienholderBox" + i).offsetTop +
                document.getElementById("lienholderMainBox").offsetTop
            });
            break;
          }
        }
        this.$emit("changeOrder", focusIndex);
      }
    },
    "lienholder.lienPrecedenceNo": function (newVal) {
      this.precedence = newVal;
      this.lienholderLookupID = "";
      this.showModal = false;
    },
    lienholder: {
      handler(lienholder) {
        if (!this.readonly) this.validateSchema(lienholder);
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.unverified {
  border: 2px solid;
  border-color: #e0585a !important;
}
.verified {
  border: 2px solid;
  border-color: #c7ffb3 !important;
}

#nameSearchButton {
  margin-top: 0px;
  margin-left: -40px;
}
.modalCloseButton {
  width: 100%;
  text-align: right;
}
.modalScroller {
  padding: 5px;
  min-width: 70vw;
  min-height: 80vh;
}
::v-deep #lienholderGrid .panel {
  top: 40px;
}
::v-deep #lienholderGrid .tableDiv {
  margin-top: 140px;
  height: calc(90vh - 300px);
}
#loadingDiv {
  margin-top: -45px;
  padding: 0;
  margin-bottom: 15px;
}
#loadingDiv h2 {
  margin: 0;
}
.PrecedenceField {
  width: 5%;
  min-width: 40px;
  margin-right: 10px;
}
.lienID {
  width: 20%;
  min-width: 40px;
  margin-right: 5px;
}
::v-deep .address-block {
  width: 100%;
}
.flex {
  display: flex;
  flex-direction: row;
  width: 96%;
}
.subTable {
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.nameField {
  min-width: 100px;
  width: 96%;
  margin-right: 10px;
}
.close {
  position: absolute;
  right: 0;
  top: 0;
}
.lienholder {
  position: relative;
}
.flex-center {
  display: flex;
  justify-content: center;
}
</style>
