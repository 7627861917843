<template>
  <div v-if="showSpecialNeedsToggle">
    <div v-if="!readonly">
      <v-switch
        label="Special Needs"
        v-model="showSpecialNeedsOptions"
        :readonly="readonly"
      />
    </div>
    <div v-if="showSpecialNeedsOptions">
      <v-checkbox
        label="Hearing Impaired"
        v-model="owner.hearingSpecNeeds"
        :readonly="readonly"
      />
      <v-checkbox
        label="Deaf"
        v-model="owner.deafSpecNeeds"
        :readonly="readonly"
      />
      <v-checkbox
        label="Intellectual Disability"
        v-model="owner.intellectualSpecNeeds"
        :readonly="readonly"
      />
      <v-checkbox
        label="Developmental Disability"
        v-model="owner.developmentalSpecNeeds"
        :readonly="readonly"
      />
      <v-checkbox
        label="Medical Condition"
        v-model="owner.medicalSpecNeeds"
        :readonly="readonly"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showSpecialNeedsOptions: false,
      showSpecialNeedsToggle: true
    };
  },
  props: {
    owner: { type: Object, required: true },
    readonly: {
      type: Boolean,
      default: false
    },
    nameCode: { required: true }
  },
  created() {
    if (
      (this.owner?.deafSpecNeeds ||
        this.owner?.hearingSpecNeeds ||
        this.owner?.intellectualSpecNeeds ||
        this.owner?.developmentalSpecNeeds ||
        this.owner?.medicalSpecNeeds) &&
      this.nameCode !== "3"
    ) {
      this.showSpecialNeedsOptions = true;
    } else if (this.readonly) {
      this.showSpecialNeedsToggle = false;
    }
  },
  watch: {
    showSpecialNeedsOptions(val) {
      if (val === false) {
        this.owner.deafSpecNeeds = false;
        this.owner.hearingSpecNeeds = false;
        this.owner.intellectualSpecNeeds = false;
        this.owner.developmentalSpecNeeds = false;
        this.owner.medicalSpecNeeds = false;
      }
    },
    nameCode(val) {
      if (val === "3") {
        this.showSpecialNeedsOptions = false;
        this.showSpecialNeedsToggle = false;
      } else if (!this.readonly) {
        this.showSpecialNeedsToggle = true;
      }
    }
  }
};
</script>
<style scoped></style>
