var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showFeeExceptions)?_c('div',{staticClass:"exceptions"},[_c('div',[_c('v-checkbox',{attrs:{"label":"VA Grant"},model:{value:(_vm.vaGrant),callback:function ($$v) {_vm.vaGrant=$$v},expression:"vaGrant"}}),(_vm.vaGrant)?[_c('v-text-field',_vm._b({attrs:{"label":"Grant Number"},model:{value:(_vm.processObj.ownership.vaGrantNo),callback:function ($$v) {_vm.$set(_vm.processObj.ownership, "vaGrantNo", $$v)},expression:"processObj.ownership.vaGrantNo"}},'v-text-field',
            _vm.feeExceptionValidation(_vm.vaGrant, _vm.processObj.ownership.vaGrantNo)
          ,false)),_c('v-text-field',_vm._b({attrs:{"label":"Grant Amount"},model:{value:(_vm.processObj.ownership.vaGrantAmount),callback:function ($$v) {_vm.$set(_vm.processObj.ownership, "vaGrantAmount", _vm._n($$v))},expression:"processObj.ownership.vaGrantAmount"}},'v-text-field',
            _vm.feeExceptionValidation(
              _vm.vaGrant,
              _vm.processObj.ownership.vaGrantAmount
            )
          ,false))]:_vm._e()],2),_c('div',[_c('v-checkbox',{attrs:{"label":"AG Grant"},model:{value:(_vm.agGrant),callback:function ($$v) {_vm.agGrant=$$v},expression:"agGrant"}}),(_vm.agGrant)?[_c('v-text-field',_vm._b({attrs:{"label":"Grant Number"},model:{value:(_vm.processObj.titleTaxExemption.exemptionNo),callback:function ($$v) {_vm.$set(_vm.processObj.titleTaxExemption, "exemptionNo", $$v)},expression:"processObj.titleTaxExemption.exemptionNo"}},'v-text-field',
            _vm.feeExceptionValidation(
              _vm.agGrant,
              _vm.processObj.titleTaxExemption.exemptionNo
            )
          ,false))]:_vm._e()],2),_c('div',[(_vm.hasRegistration)?_c('v-checkbox',{attrs:{"label":"Fire Fighter"},model:{value:(_vm.processObj.registration.firefighterInd),callback:function ($$v) {_vm.$set(_vm.processObj.registration, "firefighterInd", $$v)},expression:"processObj.registration.firefighterInd"}}):_vm._e()],1),_c('div',[(_vm.hasRegistration)?_c('v-checkbox',{attrs:{"label":"EMS"},model:{value:(_vm.processObj.registration.rescueInd),callback:function ($$v) {_vm.$set(_vm.processObj.registration, "rescueInd", $$v)},expression:"processObj.registration.rescueInd"}}):_vm._e()],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }