<template>
  <v-text-field
    :label="label"
    v-model="renderedValue"
    :type="fieldType"
    @focus="focused"
    @blur="blurred"
    @input="changeValue"
    @wheel="$event.target.blur()"
    placeholder="$0.00"
    min="0"
    @change="renderedValue < 0 ? (renderedValue = 0) : null"
    v-bind="$attrs"
  >
    <template v-slot:prepend>
      <slot name="prepend"></slot>
    </template>
    <template v-slot:append>
      <slot name="append"></slot>
    </template>
    <template v-slot:append-outer>
      <slot name="append-outer"></slot>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "bisFinanceInput",
  props: {
    label: {
      type: String
    },
    value: {
      type: [String, Number],
      required: true
    }
  },
  data: () => ({
    renderedValue: "",
    fieldType: "text",
    isFocused: false
  }),
  methods: {
    focused() {
      this.isFocused = true;
      this.fieldType = "number";
      this.renderedValue = this.renderedValue.replace("$", "");
      if (this.renderedValue === "0.00") {
        this.renderedValue = "";
      }
    },
    blurred() {
      this.isFocused = false;
      if (this.renderedValue === "") {
        this.renderedValue = 0;
      }
      this.renderedValue = parseFloat(this.renderedValue).toFixed(2);
      this.fieldType = "text";
      this.renderedValue = `$${this.renderedValue}`;
    },
    changeValue(e) {
      if (e === "" || e < 0) {
        this.$emit("input", 0);
      } else {
        this.$emit("input", parseFloat(e).toFixed(2));
      }
    }
  },
  created() {
    this.renderedValue = parseFloat(this.value).toFixed(2);
    this.renderedValue = `$${this.renderedValue}`;
  },
  watch: {
    value(newVal) {
      if (this.isFocused) return;

      this.renderedValue = parseFloat(newVal).toFixed(2);
      this.renderedValue = `$${this.renderedValue}`;
    }
  }
};
</script>

<style></style>
