<template>
  <div>
    <v-switch v-if="!readonly" v-model="overrideFees" label="Override Fees" />
    <v-text-field
      v-if="overrideFees"
      label="Override Comment"
      :error="comment == '' && overrideFees"
      :error-messages="
        comment == '' && overrideFees ? 'Required when overriding fees' : ''
      "
      @input="val => $store.commit('overrideComment', val)"
      v-model="comment"
    />
    <div v-for="group in sortedFees" :key="group">
      <h2>{{ group }}</h2>
      <div
        v-for="fee in fees.filter(fee => fee.feeGroup == group)"
        :key="fee.feeName"
      >
        <template
          v-if="
            ((fee.isEditable && fee.isOptional) || overrideFees) && !readonly
          "
        >
          <bis-finance-input
            :data-testid="fee.feeName"
            :label="fee.feeName"
            :id="fee.feeID.toString()"
            v-model="fee.feeAmount"
          />
        </template>
        <template v-else-if="!fee.isEditable && fee.isOptional && !readonly">
          <v-select
            :label="fee.feeName"
            :items="fee.amountOptions"
            item-text="text"
            item-value="value"
            v-model.number="fee.feeAmount"
          />
        </template>
        <template v-else>
          <v-text-field
            :label="fee.feeName"
            :id="fee.feeID.toString()"
            type="text"
            disabled
            :value="'$' + parseFloat(fee.feeAmount).toFixed(2)"
          />
        </template>
      </div>
      <v-text-field
        filled
        outlined
        :label="group + ' -Total'"
        readonly
        :value="'$' + parseFloat(categorizedFees[group]).toFixed(2)"
      />
      <hr />
    </div>
    <div>
      <br />
      <v-text-field
        label="Total"
        outlined
        filled
        readonly
        placeholder="0.00"
        :value="'$' + nonCCTotal.toFixed(2)"
      />
    </div>
  </div>
</template>

<script>
// components
import bisFinanceInput from "@/components/bisFinanceInput";

import { mapGetters } from "vuex";

// mixins
import fees from "@/mixins/fees.mixin";

export default {
  components: {
    bisFinanceInput
  },
  mixins: [fees],
  props: {
    fees: {
      type: Array,
      required: true
    },
    overrideFeesProp: Boolean,
    readonly: Boolean
  },
  data() {
    return {
      comment: "",
      overrideFees: false
    };
  },
  created() {
    this.$store.commit("overrideComment", null);
    // todo> this loop should probably move to a constructor a Transaction constructor at some point
    for (let i = 0; i < this.fees.length; i++) {
      if (this.fees[i].defaultAmount === undefined)
        this.fees[i].defaultAmount = this.fees[i].feeAmount;
    }
  },
  computed: {
    ...mapGetters({ overrideComment: "overrideComment" }),
    overrideIsValid() {
      return !this.overrideFees || this.comment !== "";
    }
  },
  methods: {
    defaultEditableFee(fee) {
      return fee == undefined || fee == "" ? 0 : fee;
    }
  },
  watch: {
    overrideFees(overrideFees) {
      this.$emit("overrideFees", overrideFees);

      if (!overrideFees) {
        this.revertFees();
        this.$store.commit("overrideComment", null);
      }
    },
    overrideFeesProp(overrideFeesProp) {
      this.overrideFees = overrideFeesProp;
    },
    overrideIsValid(overrideIsValid) {
      this.$emit("overrideIsValid", overrideIsValid);
    }
  }
};
</script>
