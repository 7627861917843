var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.multipleReturnedPlates),callback:function ($$v) {_vm.multipleReturnedPlates=$$v},expression:"multipleReturnedPlates"}},[_c('v-card',[_c('div',{staticClass:"multiPlate-dialog"},[_c('h2',[_vm._v("Multiple Results For This Plate Number.")]),_c('p',[_vm._v("Select A Plate From The List.")]),_c('hr'),_c('b-table',{staticClass:"bootstrap",attrs:{"selectable":"","select-mode":"single","hover":"","head-variant":"dark","fields":_vm.tableFields,"sort-by":'class',"responsive":"sm","items":_vm.platesList},on:{"row-clicked":function (item) { return _vm.checkPlateSearch(item); }}})],1)])],1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"titleSection"},[_c('h3',[_vm._v(_vm._s(_vm.title))]),(
          _vm._.has(_vm.plate, 'isTitleOnlyBuild') &&
          _vm.transactionType == 'Restore Vehicle Record'
        )?_c('v-switch',{attrs:{"label":"Title Only Build"},model:{value:(_vm.plate.isTitleOnlyBuild),callback:function ($$v) {_vm.$set(_vm.plate, "isTitleOnlyBuild", $$v)},expression:"plate.isTitleOnlyBuild"}}):_vm._e(),(_vm.displayAutoAssignField)?_c('div',{staticClass:"inputSection threeColumn"},[_c('v-select',{attrs:{"menu-props":"auto","items":_vm.autoAssignClasses,"label":"Auto-Assign"},on:{"change":function($event){return _vm.autoAssignNextPlate()}},model:{value:(_vm.selectedAutoAssignClass),callback:function ($$v) {_vm.selectedAutoAssignClass=$$v},expression:"selectedAutoAssignClass"}})],1):_vm._e()],1),(!_vm._.has(_vm.plate, 'isTitleOnlyBuild') || !_vm.plate.isTitleOnlyBuild)?_c('div',{staticClass:"inputSection threeColumn"},[_c('div',{staticClass:"leftInputs"},[(
            ![
              'Temporary Tag Only',
              'Temporary Operators Permit',
              'Title With Temporary Tag'
            ].includes(_vm.transactionType)
          )?_c('v-text-field',_vm._b({attrs:{"label":"Plate Number","maxlength":"7","inputAttrs":_vm.attributes.plateNo,"error":_vm.plateError.error,"messages":_vm.plateError.errorMessages,"readonly":_vm.readonly},on:{"blur":function($event){return _vm.checkPlate()},"input":function($event){_vm.plate.plateNo = _vm.plate.plateNo.toUpperCase();
            _vm.clearErrors();}},scopedSlots:_vm._u([(_vm.registration.plateTransfer)?{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-car-arrow-right")])],1)]}}],null,false,2295278202)},[_vm._v(" This plate will be transfered with this transaction ")])]},proxy:true}:null],null,true),model:{value:(_vm.plate.plateNo),callback:function ($$v) {_vm.$set(_vm.plate, "plateNo", $$v)},expression:"plate.plateNo"}},'v-text-field',Object.assign({}, _vm.attributes.plateNo, _vm.plateError),false)):_vm._e()],1),_c('div',{staticClass:"middleInputs"},[_c('v-select',_vm._b({attrs:{"menu-props":"auto","error":[undefined, null, ''].includes(_vm.plate.class) && !_vm.readonly,"error-count":"2","error-messages":_vm.classErrors(),"items":_vm.classSelectMap,"label":"Class","readonly":_vm.readonly},on:{"change":function($event){return _vm.classSelect()}},model:{value:(_vm.plate.class),callback:function ($$v) {_vm.$set(_vm.plate, "class", $$v)},expression:"plate.class"}},'v-select',_vm.attributes.class,false))],1),_c('div',{staticClass:"rightInputs"},[_c('v-select',_vm._b({attrs:{"label":"Issue Year","items":_vm.issueYearSelectMap,"error":[undefined, null, ''].includes(_vm.plate.issueYear) && !_vm.readonly,"errorMessages":[undefined, null, ''].includes(_vm.plate.issueYear)
              ? ['Required']
              : [],"menu-props":"auto","readonly":_vm.readonly},model:{value:(_vm.plate.issueYear),callback:function ($$v) {_vm.$set(_vm.plate, "issueYear", _vm._n($$v))},expression:"plate.issueYear"}},'v-select',_vm.attributes.issueYear,false))],1)]):_vm._e()]),(
      _vm.registration != undefined &&
      (!_vm._.has(_vm.plate, 'isTitleOnlyBuild') || !_vm.plate.isTitleOnlyBuild)
    )?_c('div',{staticClass:"fullInputWidth"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.editExpirationDateDisabled ||
              !!this.processObj.registration.newRequiredClass ||
              this.processObj.requireNewMetal},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({staticClass:"selectInput"},on),[(!_vm.isPermanentPlate(_vm.registration))?_c('star-date-picker',{attrs:{"menuProps":_vm.BDPProps.Menu,"displayFormat":_vm.BDPProps.displayFormat,"textFieldProps":Object.assign({}, _vm.BDPProps.TextField,
                    {persistentHint: true}),"datePickerProps":Object.assign({}, _vm.BDPProps.DatePicker,
                    {allowedDates: _vm.getAllowedDates}),"errorMessages":_vm.verifyDate ? [] : ['Date Not End Of Month'],"disabled":_vm.editExpirationDateDisabled,"readonly":_vm.readonly,"label":(['Registration Renewal', 'Online Renewal'].includes(
                      _vm.transactionType
                    )
                      ? 'New '
                      : '') + 'Expiration Date'},model:{value:(_vm.registration.registrationExpiresDate),callback:function ($$v) {_vm.$set(_vm.registration, "registrationExpiresDate", $$v)},expression:"registration.registrationExpiresDate"}}):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPermanentPlate(_vm.registration)),expression:"isPermanentPlate(registration)"}]},[_vm._v(" Expiration: "),_c('b',[_vm._v("Permanent")])])],1)]}}],null,false,2147395013)},[_c('span',[_vm._v("This vehicle has an incomplete transaction and the limit of courtesy renewals has been met ")])]),(_vm.transactionType == 'Registration Renewal')?_c('div',[_c('v-select',{attrs:{"readonly":_vm.readonly,"menu-props":"auto","items":_vm.vehicleUseItems,"label":"Vehicle Use"},model:{value:(_vm.vehicle.vehicleUseCode),callback:function ($$v) {_vm.$set(_vm.vehicle, "vehicleUseCode", $$v)},expression:"vehicle.vehicleUseCode"}})],1):_vm._e()],1)]),(
          !['Transaction', 'NewTransaction'].includes(
            _vm.$router.currentRoute.name
          )
        )?_c('div',[_c('v-select',{attrs:{"menu-props":"auto","id":"plateCounty","readonly":_vm.readonly,"items":_vm.convertForVSelect(_vm.countiesArray),"label":"County of Registration"},model:{value:(_vm.registration.countyID),callback:function ($$v) {_vm.$set(_vm.registration, "countyID", $$v)},expression:"registration.countyID"}})],1):_vm._e(),(_vm.countyConfig.hasWheelTaxCities)?_c('div',[_c('v-select',_vm._b({attrs:{"items":_vm.countyCities,"readonly":_vm.readonly,"label":"Wheel Tax City","item-text":"cityName","item-value":"cityID"},model:{value:(_vm.registration.wheelTaxCityID),callback:function ($$v) {_vm.$set(_vm.registration, "wheelTaxCityID", $$v)},expression:"registration.wheelTaxCityID"}},'v-select',_vm.attributes.wheelTax,false))],1):_vm._e(),(_vm.countyConfig.useCountyWheelTaxDecals)?_c('v-text-field',_vm._b({attrs:{"readonly":_vm.readonly && _vm.transactionType !== 'Online Renewal',"label":"County Decal Number"},model:{value:(_vm.countyData.registration.countyWheelTaxDecalNo),callback:function ($$v) {_vm.$set(_vm.countyData.registration, "countyWheelTaxDecalNo", _vm._n($$v))},expression:"countyData.registration.countyWheelTaxDecalNo"}},'v-text-field',_vm.attributes.wheelTax,false)):_vm._e(),(_vm.countyConfig.useCityWheelTaxDecals)?_c('v-text-field',_vm._b({attrs:{"readonly":_vm.readonly && _vm.transactionType !== 'Online Renewal',"label":"City Decal Number"},model:{value:(_vm.countyData.registration.cityWheelTaxDecalNo),callback:function ($$v) {_vm.$set(_vm.countyData.registration, "cityWheelTaxDecalNo", _vm._n($$v))},expression:"countyData.registration.cityWheelTaxDecalNo"}},'v-text-field',_vm.attributes.wheelTax,false)):_vm._e()],1),(
        _vm.validation &&
        _vm.validation.requiresSalesTaxPaidProof &&
        !_vm.attributes.plateNo.disabled
      )?_c('v-switch',_vm._b({attrs:{"label":"Sales Tax Paid"},model:{value:(_vm.validation.salesTaxPaidProofProvided),callback:function ($$v) {_vm.$set(_vm.validation, "salesTaxPaidProofProvided", $$v)},expression:"validation.salesTaxPaidProofProvided"}},'v-switch',_vm.attributes.classTaxPaid,false)):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }