<template>
  <div>
    <div v-if="showFeeExceptions" class="exceptions">
      <div>
        <v-checkbox label="VA Grant" v-model="vaGrant" />
        <template v-if="vaGrant">
          <v-text-field
            label="Grant Number"
            v-model="processObj.ownership.vaGrantNo"
            v-bind="
              feeExceptionValidation(vaGrant, processObj.ownership.vaGrantNo)
            "
          />
          <v-text-field
            label="Grant Amount"
            v-model.number="processObj.ownership.vaGrantAmount"
            v-bind="
              feeExceptionValidation(
                vaGrant,
                processObj.ownership.vaGrantAmount
              )
            "
          />
        </template>
      </div>
      <div>
        <v-checkbox label="AG Grant" v-model="agGrant" />
        <template v-if="agGrant">
          <v-text-field
            label="Grant Number"
            v-model="processObj.titleTaxExemption.exemptionNo"
            v-bind="
              feeExceptionValidation(
                agGrant,
                processObj.titleTaxExemption.exemptionNo
              )
            "
          />
        </template>
      </div>
      <div>
        <v-checkbox
          v-if="hasRegistration"
          label="Fire Fighter"
          v-model="processObj.registration.firefighterInd"
        />
      </div>
      <div>
        <v-checkbox
          v-if="hasRegistration"
          label="EMS"
          v-model="processObj.registration.rescueInd"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeeExceptions",
  props: {
    processObj: {
      type: Object,
      required: true
    },
    showFeeExceptions: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    vaGrant: true,
    agGrant: true,
    hasRegistration: true
  }),
  created() {
    if (this._.isEmpty(this.processObj.ownership?.vaGrantNo)) {
      this.vaGrant = false;
    }
    if (this.processObj.titleTaxExemption === undefined) {
      this.agGrant = false;
    }
  },
  methods: {
    feeExceptionValidation(isActive, model) {
      if (isActive && [null, undefined, ""].includes(model)) {
        return { error: true, "error-messages": ["Required"] };
      } else {
        return { error: false };
      }
    }
  },
  watch: {
    vaGrant(isActive) {
      if (!isActive && this._.has(this.processObj, "ownership")) {
        this.processObj.ownership.vaGrantNo = "";
        this.processObj.ownership.vaGrantAmount = 0;
      }
    },
    agGrant(isActive) {
      if (!isActive) {
        delete this.processObj.titleTaxExemption;
      } else {
        this.$set(this.processObj, "titleTaxExemption", {
          exemptionNo: "",
          exemptionTypeCode: "1"
        });
      }
    },
    processObj: {
      handler() {
        if (this.processObj.registration) this.hasRegistration = true;
        else this.hasRegistration = false;
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped>
.exceptions {
  display: flex;
  justify-content: space-around;
}
.exceptions * {
  width: 90%;
}
</style>
