<template>
  <div class="pt-3 maintMain">
    <v-autocomplete
      label="User"
      :items="userSelectOptions"
      v-model="userID"
      class="maintUserInput"
      @input="maintenance.createdUserID = userID"
    />
    <div class="maintInput">
      <v-select
        label="County"
        :items="counties"
        :disabled="true"
        return-object
        item-text="countyName"
        v-model="countySelect"
      />
      <v-select
        label="Office"
        :disabled="offices.length <= 1"
        :items="offices"
        return-object
        item-text="officeName"
        class="maintInput"
        v-model="officeSelect"
      />
      <v-select
        class="maintInput"
        label="Workstation"
        :disabled="workstations.length <= 1"
        :items="workstations"
        item-text="workstationName"
        return-object
        @change="maintenance.workstationNo = workstationSelect.workstationName"
        v-model="workstationSelect"
      />
      <v-text-field
        label="Drawer"
        v-model="drawerNo"
        class="maintInput"
        @input="maintenance.drawerNo = drawerNo"
      />
      <v-text-field
        label="Invoice"
        v-model.number="invoiceNo"
        class="maintInput"
        @input="maintenance.invoiceNo = invoiceNo"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    mostRecentTrans: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    }
  },
  data() {
    return {
      workstationNo: this.mostRecentTrans.workstationNo,
      countyID: this.mostRecentTrans.countyID,
      siteNo: this.mostRecentTrans.siteNo,
      drawerNo: this.mostRecentTrans.drawerNo,
      invoiceNo: this.mostRecentTrans.invoiceNo,
      countySelect: {},
      officeSelect: {},
      workstationSelect: {},
      userID: this.mostRecentTrans.createdUserID
    };
  },

  computed: {
    ...mapGetters({
      counties: "locations",
      users: "users",
      maintenance: "maintenance"
    }),
    offices() {
      return this._.get(this.countySelect, "offices") || [];
    },
    workstations() {
      return this._.get(this.officeSelect, "workstations") || [];
    },
    userSelectOptions() {
      return this.users.map(user => ({
        text: user.username,
        value: user.id
      }));
    }
  },
  mounted() {
    if (this.countyID != undefined && Array.isArray(this.counties)) {
      this.countySelect = this.counties.find(
        county => county.countyID === this.countyID
      );
      if (this.siteNo !== undefined) {
        // Defaults to the first office if only one is present
        if (this.countySelect.offices.length === 1) {
          this.officeSelect = this.countySelect.offices[0];
        } else {
          this.officeSelect = this.countySelect.offices.find(
            office => office.siteNo === this.siteNo
          );
        }
        if (this.workstationNo !== undefined) {
          // Defaults to the first workstation if only one is present
          if (this.officeSelect.workstations.length === 1) {
            this.workstationSelect = this.officeSelect.workstations[0];
          } else {
            this.workstationSelect = this.officeSelect.workstations.find(
              workstation => workstation.workstationName === this.workstationNo
            );
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.maintInput {
  display: flex;
  flex-direction: row;
}
.maintUserInput {
  width: 50%;
}
.maintMain {
  margin-left: 20%;
  width: 80%;
}
</style>
