<template>
  <div class="flex">
    <div class="titleSection">
      <h3>Placard</h3>
    </div>
    <div class="inputSection twoColumn">
      <div class="leftInputs">
        <v-text-field
          @input="placard.controlNo = placard.controlNo.toUpperCase()"
          label="Placard Number"
          :readonly="readonly && transactionType !== 'Online Renewal'"
          @blur="getPlacardInfo(placard.controlNo)"
          v-model="placard.controlNo"
          v-bind="placardError"
        />
        <v-text-field
          disabled
          v-show="
            placard.oldControlNo != undefined && placard.oldControlNo != ''
          "
          label="Old Placard Number"
          :value="placard.oldControlNo"
        />
      </div>

      <div class="rightInputs">
        <star-date-picker
          v-model="placard.endDate"
          :menuProps="BDPProps.Menu"
          modelFormat="YYYY-MM-DDTHH:mm:ssZ"
          :displayFormat="BDPProps.displayFormat"
          :textFieldProps="{ ...BDPProps.TextField, persistentHint: true }"
          :datePickerProps="customDatePickerProps"
          :disabled="
            ((this._.has(placard, 'oldControlNo') &&
              placard.oldControlNo != '') ||
              readonly) &&
            transactionType != 'Replace Placard'
          "
          label="Expiration Date"
        />
        <v-select
          label="Type"
          :readonly="readonly"
          v-model="placardType"
          :items="placardTypes"
        /><!-- todo> this can be read only when placard.oldControlNo is present once wheelchair/veteran/etc flags are returned by the api correctly -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import transaction from "@/mixins/transaction.mixin";
import starDatePicker from "@/components/nonPageComponents/starDatePicker";
import { formatErrorResponse } from "@/assets/js/format.js";

export default {
  mixins: [transaction],
  components: { starDatePicker },
  props: {
    placard: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    },
    readonly: { type: Boolean, default: false, required: false }
  },
  data() {
    return {
      placardError: {}
    };
  },
  methods: {
    async getPlacardInfo(placardNo) {
      if (
        ![
          "New Placard",
          "Placard Renewal",
          "Replace Placard",
          "Delete Placard",
          "Online Renewal"
        ].includes(this.transactionType)
      )
        return;

      if (this.readonly && this.transactionType !== "Online Renewal") return;

      this.placard.placardClass = "";
      this.placard.placardTypeCode = "";
      this.placardError = { error: false, errorMessages: [] };

      if ([null, undefined, ""].includes(placardNo)) {
        this.placardError = { error: true, errorMessages: ["Required"] };
        return;
      }

      try {
        const placardInfo = await this.$api.subRangePlateSearch(placardNo, {
          500: () => {
            this.placardError = {
              error: true,
              errorMessages: ["Server problem"]
            };
          },
          404: () => {
            this.placardError = {
              error: true,
              errorMessages: ["Placard not found"]
            };
          },
          422: async error => {
            this.placardError = {
              error: true,
              errorMessages: [formatErrorResponse(error)]
            };
          }
        });
        if (this._.has(placardInfo, "[0].plateClassCode"))
          this.placard.placardClass = placardInfo[0].plateClassCode;
        if (this._.has(placardInfo, "[0].issueYear"))
          this.placard.issueYear = placardInfo[0].issueYear;
      } catch (e) {
        console.error(e);
      }
    }
  },
  computed: {
    ...mapGetters({
      transactionType: "transactionType",
      BDPProps: "BDPProps",
      processObj: "processObj"
    }),
    customDatePickerProps() {
      return Object.assign(
        {
          allowedDates: this.getAllowedDates
        },
        this.BDPProps.DatePicker
      );
    },
    placardTypes() {
      switch (this.placard.placardClass) {
        case "1020":
          return [{ value: "decal", text: "Decal" }];
        case "1014":
          return [{ value: "temporary", text: "Temporary" }];
        case "1016":
          return [
            { value: "permanent", text: "Permanent" },
            { value: "plate", text: "Plate" },
            { value: "wheelchair", text: "Wheelchair" },
            { value: "veteran", text: "Veteran" }
          ];
        default:
          return [];
      }
    },
    placardType: {
      get() {
        const placard = this.placard;

        if (placard.placardClass === "1020") return "decal";
        if (placard.placardTypeCode === "T") return "temporary";
        if (placard.veteran) return "veteran";
        if (placard.platePlacard) return "plate";
        if (placard.wheelchair) return "wheelchair";
        if (placard.placardTypeCode === "P") return "permanent";
        return "";
      },
      set(placardType) {
        const placard = this.placard;
        if (
          ["permanent", "plate", "wheelchair", "veteran", "decal"].includes(
            placardType
          )
        ) {
          placard.placardTypeCode = "P";
          placard.placardClass = "1016";
        } else {
          placard.placardTypeCode = "T";
          placard.placardClass = "1014";
        }
        placard.platePlacard = placardType === "plate";
        placard.wheelchair = placardType === "wheelchair";
        placard.veteran = placardType === "veteran"; // todo> verify prop names
        if (placardType === "decal") placard.placardClass = "1020";
      }
    }
  },
  async created() {
    if (
      ["New Placard"].includes(this.transactionType) &&
      this.processObj?.placard?.controlNo !== undefined
    ) {
      this.placard.controlNo = this.processObj.placard.controlNo;
    }

    if (!this.readonly && this.transactionType !== "Replace Placard") {
      this.placard.beginDate = new Date();
      this.placard.previousEndDate =
        this.placard.previousEndDate ||
        Object.freeze(dayjs(this.placard.endDate));
      await this.getPlacardInfo(this.placard.controlNo);
    }
  },
  watch: {
    "placard.placardTypeCode"() {
      this.defaultPlacardEndDate();
    },
    "placard.placardClass"() {
      switch (this.placard.placardClass) {
        case "1020":
          this.placardType = "decal";
          this.placard.placardTypeCode = "P";
          break;
        case "1014":
          this.placard.placardTypeCode = "T";
          this.placardType = "temporary";
          break;
        case "1016":
          this.placard.placardTypeCode = "P";
          this.placardType = "permanent";
          break;
      }
    }
  }
};
</script>
