export default {
  computed: {
    nonCCTotal() {
      let total = 0;

      for (let i = 0; i < this.fees.length; i++) {
        if (!this.fees[i].isCreditCardFee) {
          // exclude credit card fees
          const feeAmount = parseFloat(this.fees[i].feeAmount);
          if (!isNaN(feeAmount)) {
            // if fee is a number
            total += parseFloat(this.fees[i].feeAmount);
          }
        }
      }

      return Math.round(total * 100) / 100;
    },
    ccFeeTotal() {
      let ccFeeTotal = 0;

      for (let i = 0; i < this.fees.length; i++) {
        if (this.fees[i].isCreditCardFee)
          ccFeeTotal += parseFloat(this.fees[i].feeAmount);
      }

      return Math.round(ccFeeTotal * 100) / 100;
    },
    categorizedFees() {
      const categorizedFees = {};

      for (let i = 0; i < this.fees.length; i++) {
        if (categorizedFees[this.fees[i].feeGroup] === undefined) {
          if (
            this.fees[i].feeAmount != "" &&
            this.fees[i].feeAmount != undefined
          )
            categorizedFees[this.fees[i].feeGroup] = this.fees[i].feeAmount;
          else categorizedFees[this.fees[i].feeGroup] = 0;
        } else {
          if (
            this.fees[i].feeAmount != "" &&
            this.fees[i].feeAmount != undefined
          )
            categorizedFees[this.fees[i].feeGroup] =
              parseFloat(categorizedFees[this.fees[i].feeGroup]) +
              parseFloat(this.fees[i].feeAmount);
        }
      }

      return categorizedFees;
    },
    sortedFees() {
      return Object.keys(this.categorizedFees).sort((a, b) => {
        return b.localeCompare(a);
      });
    }
  },
  methods: {
    async getCreditCardFees(amount) {
      const ccFees = await this.$api.getCreditCardFees(
        this.$store.getters.countyId,
        amount,
        true
      );
      ccFees.forEach(fee => {
        fee.isCreditCardFee = true;
        fee.defaultAmount = fee.feeAmount;
      });
      return ccFees;
    },
    revertFees() {
      for (let i = 0; i < this.fees.length; i++) {
        this.fees[i].feeAmount = this.fees[i].defaultAmount;
      }
    },
    removeCreditCardFees() {
      for (let i = this.fees.length - 1; i >= 0; i--) {
        if (this.fees[i].feeGroup === "Credit Card") {
          // todo> should we use the isCreditCardFee flag instead?
          this.fees.splice(i, 1);
        }
      }
    },
    // todo> this can be incorporated into a fee constructor once usages of this function don't affect objects that are already watched by vue
    addAmountOptions(fee, isEdit = false) {
      if (fee.isEditable === false && fee.isOptional === true) {
        fee.amountOptions = [
          {
            value: 0,
            text: "$0.00"
          },
          {
            value: fee.feeAmount,
            text: `$${parseFloat(fee.feeAmount).toFixed(2)}`
          }
        ];
        if (!isEdit && fee.isDefaultZero === true) {
          fee.feeAmount = 0;
          fee.defaultAmount = 0;
        }
      } else {
        fee.defaultAmount = fee.feeAmount;
      }
    }
  }
};
