var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"titleSection"},[_c('h3',[_vm._v(" "+_vm._s(_vm.title)+" ")])]),(_vm._.has(_vm.ownership, 'nameCode') && !_vm.isPlacard)?_c('div',{staticClass:"inputSection twoColumn"},[_c('div',{staticClass:"rightInputs"},[_c('div',{staticClass:"flex"},[_c('v-select',_vm._b({staticClass:"small-select",attrs:{"items":[
              { text: 'One Name', value: '0' },
              { text: 'Two First & One Last Name', value: '1' },
              { text: 'Two First & Two Last Names', value: '2' },
              { text: 'Company / Business', value: '3' }
            ],"readonly":_vm.nameCodeIsReadOnly || _vm.readonly,"label":"Name Code"},model:{value:(_vm.ownership.nameCode),callback:function ($$v) {_vm.$set(_vm.ownership, "nameCode", $$v)},expression:"ownership.nameCode"}},'v-select',_vm.attributes.nameCode,false)),_c('v-spacer'),(!_vm.readonly)?_c('v-switch',{attrs:{"label":"Show Fee Exceptions"},model:{value:(_vm.showFeeExceptions),callback:function ($$v) {_vm.showFeeExceptions=$$v},expression:"showFeeExceptions"}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(_vm.randomizeButtonIsShown && !_vm.readonly)?_c('v-btn',_vm._g({on:{"click":function($event){return _vm.$emit('randomizeButtonPress')}}},on),[_c('v-icon',[_vm._v("shuffle")])],1):_vm._e()]}}],null,false,2537884342)},[_c('span',[_vm._v("Randomize Owner Information")])])],1)])]):(
        _vm._.has(_vm.ownership, 'nameCode') &&
        _vm.isPlacard &&
        _vm.ownership.nameCode !== '0'
      )?_c('div',[_c('v-select',{attrs:{"items":[
          { text: 'Owner 1', value: '0' },
          { text: 'Owner 2', value: '1' }
        ],"label":"Owner Index"},model:{value:(_vm.placardOwnerIndex),callback:function ($$v) {_vm.placardOwnerIndex=$$v},expression:"placardOwnerIndex"}})],1):_vm._e()]),(
      _vm.owners.length > 0 &&
      (!_vm.isPlacard || _vm.ownership === undefined || _vm.nameCode === '0')
    )?_c('div',{staticClass:"fullInputWidth"},[_c('div',{staticClass:"rightInputs"},[_c('div',{staticClass:"flex"},[(_vm._.has(_vm.ownership, 'nameCode'))?_c('div',{staticClass:"ownerLabel"},[_c('label',[_vm._v("Owner 1")])]):_vm._e(),_c('div',[_c('div',[(
                !_vm._.has(_vm.ownership, 'nameCode') ||
                (_vm._.has(_vm.ownership, 'nameCode') && _vm.ownership.nameCode != 3)
              )?_c('div',{staticClass:"flex owner-name"},[_c('v-text-field',_vm._b({attrs:{"label":"Last Name","readonly":_vm.readonly},on:{"input":function($event){_vm.owners[0].individualCustomer.lastName =
                    _vm.owners[0].individualCustomer.lastName.toUpperCase()}},model:{value:(_vm.owners[0].individualCustomer.lastName),callback:function ($$v) {_vm.$set(_vm.owners[0].individualCustomer, "lastName", $$v)},expression:"owners[0].individualCustomer.lastName"}},'v-text-field',
                  Object.assign(
                    {},
                    _vm.validate('.individualCustomer.lastName'),
                    _vm.attributes.ownerName
                  )
                ,false)),_c('v-text-field',_vm._b({attrs:{"readonly":_vm.readonly,"label":"First Name"},on:{"input":function($event){_vm.owners[0].individualCustomer.firstName =
                    _vm.owners[0].individualCustomer.firstName.toUpperCase()}},model:{value:(_vm.owners[0].individualCustomer.firstName),callback:function ($$v) {_vm.$set(_vm.owners[0].individualCustomer, "firstName", $$v)},expression:"owners[0].individualCustomer.firstName"}},'v-text-field',
                  Object.assign(
                    {},
                    _vm.validate('.individualCustomer.firstName'),
                    _vm.attributes.ownerName
                  )
                ,false)),_c('v-text-field',_vm._b({attrs:{"readonly":_vm.readonly,"label":"Middle Name"},on:{"input":function($event){_vm.owners[0].individualCustomer.middleName =
                    _vm.owners[0].individualCustomer.middleName.toUpperCase()}},model:{value:(_vm.owners[0].individualCustomer.middleName),callback:function ($$v) {_vm.$set(_vm.owners[0].individualCustomer, "middleName", $$v)},expression:"owners[0].individualCustomer.middleName"}},'v-text-field',
                  Object.assign(
                    {},
                    _vm.validate('.individualCustomer.middleName'),
                    _vm.attributes.ownerName
                  )
                ,false)),_c('v-text-field',_vm._b({attrs:{"readonly":_vm.readonly,"label":"Suffix"},on:{"input":function($event){_vm.owners[0].individualCustomer.nameSuffix =
                    _vm.owners[0].individualCustomer.nameSuffix.toUpperCase()}},model:{value:(_vm.owners[0].individualCustomer.nameSuffix),callback:function ($$v) {_vm.$set(_vm.owners[0].individualCustomer, "nameSuffix", $$v)},expression:"owners[0].individualCustomer.nameSuffix"}},'v-text-field',
                  Object.assign(
                    {},
                    _vm.validate('.individualCustomer.nameSuffix'),
                    _vm.attributes.ownerName
                  )
                ,false))],1):_c('div',{attrs:{"id":"businessName"}},[_c('v-text-field',_vm._b({attrs:{"readonly":_vm.readonly,"label":"Business Name"},on:{"input":function($event){_vm.owners[0].customerName =
                    _vm.owners[0].customerName.toUpperCase()}},model:{value:(_vm.owners[0].customerName),callback:function ($$v) {_vm.$set(_vm.owners[0], "customerName", $$v)},expression:"owners[0].customerName"}},'v-text-field',
                  Object.assign(
                    {},
                    _vm.validate('.customerName'),
                    _vm.attributes.ownerName
                  )
                ,false))],1)]),(!_vm.isPlacard)?[_c('special-needs',{attrs:{"owner":_vm.owners[0],"readonly":_vm.readonly,"nameCode":_vm.nameCode}})]:_vm._e()],2)]),(
          _vm._.has(_vm.ownership, 'nameCode') &&
          (_vm.ownership.nameCode == 1 || _vm.ownership.nameCode == 2) &&
          _vm.owners[1] != undefined
        )?_c('div',{staticClass:"flex"},[_vm._m(0),_c('div',[_c('div',{staticClass:"flex owner-name"},[(_vm.ownership.nameCode != 1)?_c('v-text-field',_vm._b({attrs:{"readonly":_vm.readonly,"label":"Last Name"},on:{"input":function($event){_vm.owners[1].individualCustomer.lastName =
                  _vm.owners[1].individualCustomer.lastName.toUpperCase()}},model:{value:(_vm.owners[1].individualCustomer.lastName),callback:function ($$v) {_vm.$set(_vm.owners[1].individualCustomer, "lastName", $$v)},expression:"owners[1].individualCustomer.lastName"}},'v-text-field',
                Object.assign(
                  {},
                  _vm.validate('.individualCustomer.lastName', _vm.owners1Errors),
                  _vm.attributes.ownerName
                )
              ,false)):_vm._e(),_c('v-text-field',_vm._b({attrs:{"label":"First Name","readonly":_vm.readonly},on:{"input":function($event){_vm.owners[1].individualCustomer.firstName =
                  _vm.owners[1].individualCustomer.firstName.toUpperCase()}},model:{value:(_vm.owners[1].individualCustomer.firstName),callback:function ($$v) {_vm.$set(_vm.owners[1].individualCustomer, "firstName", $$v)},expression:"owners[1].individualCustomer.firstName"}},'v-text-field',
                Object.assign(
                  {},
                  _vm.validate('.individualCustomer.firstName', _vm.owners1Errors),
                  _vm.attributes.ownerName
                )
              ,false)),_c('v-text-field',_vm._b({attrs:{"readonly":_vm.readonly,"label":"Middle Name"},on:{"input":function($event){_vm.owners[1].individualCustomer.middleName =
                  _vm.owners[1].individualCustomer.middleName.toUpperCase()}},model:{value:(_vm.owners[1].individualCustomer.middleName),callback:function ($$v) {_vm.$set(_vm.owners[1].individualCustomer, "middleName", $$v)},expression:"owners[1].individualCustomer.middleName"}},'v-text-field',
                Object.assign(
                  {},
                  _vm.validate('.individualCustomer.middleName', _vm.owners1Errors),
                  _vm.attributes.ownerName
                )
              ,false)),_c('v-text-field',_vm._b({attrs:{"label":"Suffix","readonly":_vm.readonly},on:{"input":function($event){_vm.owners[1].individualCustomer.nameSuffix =
                  _vm.owners[1].individualCustomer.nameSuffix.toUpperCase()}},model:{value:(_vm.owners[1].individualCustomer.nameSuffix),callback:function ($$v) {_vm.$set(_vm.owners[1].individualCustomer, "nameSuffix", $$v)},expression:"owners[1].individualCustomer.nameSuffix"}},'v-text-field',
                Object.assign(
                  {},
                  _vm.validate('.individualCustomer.nameSuffix', _vm.owners1Errors),
                  _vm.attributes.ownerName
                )
              ,false))],1),(!_vm.isPlacard)?[_c('special-needs',{attrs:{"owner":_vm.owners[1],"readonly":_vm.readonly,"nameCode":_vm.nameCode}})]:_vm._e()],2)]):_vm._e(),(_vm._.has(_vm.ownership, 'conjunctionCode'))?_c('div',{staticClass:"flex"},[(_vm.ownership.nameCode == 1 || _vm.ownership.nameCode == 2)?_c('v-select',_vm._b({attrs:{"items":[
            { text: '', value: '0' },
            { text: 'And', value: '1' },
            { text: 'Or', value: '2' }
          ],"error-messages":_vm.ownership.conjunctionCode == 0 ? 'Required' : '',"error":_vm.ownership.conjunctionCode == 0,"readonly":_vm.readonly,"label":"Conjunction"},model:{value:(_vm.ownership.conjunctionCode),callback:function ($$v) {_vm.$set(_vm.ownership, "conjunctionCode", $$v)},expression:"ownership.conjunctionCode"}},'v-select',_vm.attributes.conjunctionCode,false)):_vm._e()],1):_vm._e(),(
          ['Re-Title Only', 'Re-Title and Register'].includes(
            _vm.transactionType
          ) &&
          _vm.ownership.nameCode === '0' &&
          _vm._.has(_vm.ownership, 'spouseDateOfDeath')
        )?_c('starDatePicker',{attrs:{"label":"Spouse Date of Death","disabled":_vm.readonly,"displayFormat":_vm.BDPProps.displayFormat,"textFieldProps":_vm.BDPProps.TextField,"datePickerProps":_vm.BDPProps.DatePicker,"menuProps":_vm.BDPProps.Menu,"allowEmpty":true,"modelFormat":"YYYY-MM-DDTHH:mm:ssZ"},model:{value:(_vm.ownership.spouseDateOfDeath),callback:function ($$v) {_vm.$set(_vm.ownership, "spouseDateOfDeath", $$v)},expression:"ownership.spouseDateOfDeath"}}):_vm._e(),_c('div',{staticClass:"flex"},[(!_vm.isPlacard)?_c('v-text-field',_vm._b({attrs:{"label":"In Care Of","readonly":_vm.readonly},on:{"input":function($event){_vm.owners[0].inCareOf = _vm.owners[0].inCareOf.toUpperCase()}},model:{value:(_vm.owners[0].inCareOf),callback:function ($$v) {_vm.$set(_vm.owners[0], "inCareOf", $$v)},expression:"owners[0].inCareOf"}},'v-text-field',_vm.validate('.inCareOf'),false)):_vm._e()],1),(!_vm.readonly)?_c('fee-exceptions',{attrs:{"processObj":_vm.processObj,"showFeeExceptions":_vm.showFeeExceptions}}):_vm._e()],1)]):_c('div',[_c('div',{staticClass:"fullInputWidth"},[_c('div',{staticClass:"rightInputs"},[_c('div',{staticClass:"flex"},[_c('v-text-field',_vm._b({attrs:{"label":"Last Name","readonly":_vm.readonly},on:{"input":function($event){_vm.owners[_vm.placardOwnerIndex].individualCustomer.lastName =
                _vm.owners[
                  _vm.placardOwnerIndex
                ].individualCustomer.lastName.toUpperCase()}},model:{value:(_vm.owners[_vm.placardOwnerIndex].individualCustomer.lastName),callback:function ($$v) {_vm.$set(_vm.owners[_vm.placardOwnerIndex].individualCustomer, "lastName", $$v)},expression:"owners[placardOwnerIndex].individualCustomer.lastName"}},'v-text-field',
              Object.assign(
                {},
                _vm.validate('.individualCustomer.lastName'),
                _vm.attributes.ownerName
              )
            ,false)),_c('v-text-field',_vm._b({attrs:{"readonly":_vm.readonly,"label":"First Name"},on:{"input":function($event){_vm.owners[_vm.placardOwnerIndex].individualCustomer.firstName =
                _vm.owners[
                  _vm.placardOwnerIndex
                ].individualCustomer.firstName.toUpperCase()}},model:{value:(_vm.owners[_vm.placardOwnerIndex].individualCustomer.firstName),callback:function ($$v) {_vm.$set(_vm.owners[_vm.placardOwnerIndex].individualCustomer, "firstName", $$v)},expression:"owners[placardOwnerIndex].individualCustomer.firstName"}},'v-text-field',
              Object.assign(
                {},
                _vm.validate('.individualCustomer.firstName'),
                _vm.attributes.ownerName
              )
            ,false)),_c('v-text-field',_vm._b({attrs:{"readonly":_vm.readonly,"label":"Middle Name"},on:{"input":function($event){_vm.owners[_vm.placardOwnerIndex].individualCustomer.middleName =
                _vm.owners[
                  _vm.placardOwnerIndex
                ].individualCustomer.middleName.toUpperCase()}},model:{value:(_vm.owners[_vm.placardOwnerIndex].individualCustomer.middleName),callback:function ($$v) {_vm.$set(_vm.owners[_vm.placardOwnerIndex].individualCustomer, "middleName", $$v)},expression:"owners[placardOwnerIndex].individualCustomer.middleName"}},'v-text-field',
              Object.assign(
                {},
                _vm.validate('.individualCustomer.middleName'),
                _vm.attributes.ownerName
              )
            ,false)),_c('v-text-field',_vm._b({attrs:{"readonly":_vm.readonly,"label":"Suffix"},on:{"input":function($event){_vm.owners[_vm.placardOwnerIndex].individualCustomer.nameSuffix =
                _vm.owners[
                  _vm.prePlacardOwner
                ].individualCustomer.nameSuffix.toUpperCase()}},model:{value:(_vm.owners[_vm.placardOwnerIndex].individualCustomer.nameSuffix),callback:function ($$v) {_vm.$set(_vm.owners[_vm.placardOwnerIndex].individualCustomer, "nameSuffix", $$v)},expression:"owners[placardOwnerIndex].individualCustomer.nameSuffix"}},'v-text-field',
              Object.assign(
                {},
                _vm.validate('.individualCustomer.nameSuffix'),
                _vm.attributes.ownerName
              )
            ,false))],1),(!_vm.isPlacard)?[_c('special-needs',{attrs:{"owner":_vm.owners[_vm.placardOwnerIndex],"readonly":_vm.readonly,"nameCode":_vm.nameCode}})]:_vm._e()],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ownerLabel"},[_c('label',[_vm._v("Owner 2")])])}]

export { render, staticRenderFns }