export default {
  methods: {
    convertForVSelect(keyedArray, reverseKeys = false, changeKeysToInt = true) {
      if (changeKeysToInt) {
        if (reverseKeys)
          return Object.keys(keyedArray).map(key => {
            return {
              text: key,
              value: this.parseIntStrict(keyedArray[key]) || keyedArray[key]
            };
          });
        else
          return Object.keys(keyedArray).map(key => {
            return {
              value: this.parseIntStrict(key) || key,
              text: keyedArray[key]
            };
          });
      } else {
        if (reverseKeys)
          return Object.keys(keyedArray).map(key => {
            return { text: key, value: keyedArray[key] };
          });
        else
          return Object.keys(keyedArray).map(key => {
            return { value: key, text: keyedArray[key] };
          });
      }
    },
    parseIntStrict(str) {
      if (str.match(/^-{0,1}\d+$/)) {
        return parseInt(str);
      } else {
        return NaN;
      }
    }
  }
};
