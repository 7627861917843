import Ajv from "ajv";

export default {
  data() {
    return {
      schemaJSON: {},
      validateAJV: null,
      validationErrors: []
    };
  },
  methods: {
    async loadSchemaValidator() {
       
      const ajv = new Ajv({
        loadSchema:this.getValidationSchema,
        allErrors: true,
        coerceTypes: true
      });
      try {
       this.validateAJV = await ajv.compileAsync(this.schemaJSON);
      } catch (e) {
        console.error(e);
      }
    },
    async getValidationSchema(url) {
      return this.$api.getValidationSchema(url, {
        431: () => {
          //431 seems to only happen on test when the schema locaion is not fully specified
          this.$root.$emit("push-alert", "Could not load schema file", {
            color: "error"
          });
        }
      });
    },
    async validateSchema(dataToValidate) {
      if (typeof this.validateAJV === "function") {
        this.validateAJV(this._.cloneDeep(dataToValidate));
        this.validationErrors = this.validateAJV.errors || [];
      }
    },
    validate(objLocation, customErrorsVar = this.validationErrors) {
      for (let i = 0; i < customErrorsVar.length; i++) {
        let validateCheckString = customErrorsVar[i].dataPath;
        if (customErrorsVar[i].keyword == "required") {
          validateCheckString +=
            "." + customErrorsVar[i].params.missingProperty;
        }

        if (validateCheckString == objLocation) {
          return { error: true, "error-messages": ["Required"] };
        }
      }
      return { error: false };
    }
  }
};
