<template>
  <div>
    <v-dialog width="500" v-model="multipleReturnedPlates">
      <v-card>
        <div class="multiPlate-dialog">
          <h2>Multiple Results For This Plate Number.</h2>
          <p>Select A Plate From The List.</p>
          <hr />
          <b-table
            class="bootstrap"
            selectable
            select-mode="single"
            hover
            head-variant="dark"
            :fields="tableFields"
            :sort-by="'class'"
            responsive="sm"
            :items="platesList"
            @row-clicked="item => checkPlateSearch(item)"
          />
        </div>
      </v-card>
    </v-dialog>
    <div class="flex">
      <div class="titleSection">
        <h3>{{ title }}</h3>
        <v-switch
          v-if="
            _.has(plate, 'isTitleOnlyBuild') &&
            transactionType == 'Restore Vehicle Record'
          "
          label="Title Only Build"
          v-model="plate.isTitleOnlyBuild"
        />
        <div class="inputSection threeColumn" v-if="displayAutoAssignField">
          <v-select
            menu-props="auto"
            v-model="selectedAutoAssignClass"
            @change="autoAssignNextPlate()"
            :items="autoAssignClasses"
            label="Auto-Assign"
          />
        </div>
      </div>
      <div
        class="inputSection threeColumn"
        v-if="!_.has(plate, 'isTitleOnlyBuild') || !plate.isTitleOnlyBuild"
      >
        <div class="leftInputs">
          <v-text-field
            v-if="
              ![
                'Temporary Tag Only',
                'Temporary Operators Permit',
                'Title With Temporary Tag'
              ].includes(transactionType)
            "
            label="Plate Number"
            maxlength="7"
            @blur="checkPlate()"
            @input="
              plate.plateNo = plate.plateNo.toUpperCase();
              clearErrors();
            "
            v-model="plate.plateNo"
            :inputAttrs="attributes.plateNo"
            :error="plateError.error"
            :messages="plateError.errorMessages"
            v-bind="Object.assign({}, attributes.plateNo, plateError)"
            :readonly="readonly"
          >
            <template v-if="registration.plateTransfer" v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-icon color="black">mdi-car-arrow-right</v-icon>
                  </span>
                </template>
                This plate will be transfered with this transaction
              </v-tooltip>
            </template>
          </v-text-field>
        </div>
        <div class="middleInputs">
          <v-select
            menu-props="auto"
            v-model="plate.class"
            @change="classSelect()"
            v-bind="attributes.class"
            :error="[undefined, null, ''].includes(plate.class) && !readonly"
            error-count="2"
            :error-messages="classErrors()"
            :items="classSelectMap"
            label="Class"
            :readonly="readonly"
          />
        </div>
        <div class="rightInputs">
          <v-select
            label="Issue Year"
            v-model.number="plate.issueYear"
            v-bind="attributes.issueYear"
            :items="issueYearSelectMap"
            :error="
              [undefined, null, ''].includes(plate.issueYear) && !readonly
            "
            :errorMessages="
              [undefined, null, ''].includes(plate.issueYear)
                ? ['Required']
                : []
            "
            menu-props="auto"
            :readonly="readonly"
          />
        </div>
      </div>
    </div>
    <div
      v-if="
        registration != undefined &&
        (!_.has(plate, 'isTitleOnlyBuild') || !plate.isTitleOnlyBuild)
      "
      class="fullInputWidth"
    >
      <div class="flex">
        <div class="flex">
          <div class="flex">
            <v-tooltip
              bottom
              :disabled="
                !editExpirationDateDisabled ||
                !!this.processObj.registration.newRequiredClass ||
                this.processObj.requireNewMetal
              "
            >
              <template v-slot:activator="{ on }">
                <div class="selectInput" v-on="on">
                  <star-date-picker
                    v-if="!isPermanentPlate(registration)"
                    v-model="registration.registrationExpiresDate"
                    :menuProps="BDPProps.Menu"
                    :displayFormat="BDPProps.displayFormat"
                    :textFieldProps="{
                      ...BDPProps.TextField,
                      persistentHint: true
                    }"
                    :datePickerProps="{
                      ...BDPProps.DatePicker,
                      allowedDates: getAllowedDates
                    }"
                    :errorMessages="verifyDate ? [] : ['Date Not End Of Month']"
                    :disabled="editExpirationDateDisabled"
                    :readonly="readonly"
                    :label="
                      (['Registration Renewal', 'Online Renewal'].includes(
                        transactionType
                      )
                        ? 'New '
                        : '') + 'Expiration Date'
                    "
                  />

                  <p v-show="isPermanentPlate(registration)">
                    Expiration:
                    <b>Permanent</b>
                  </p>
                </div>
              </template>
              <span
                >This vehicle has an incomplete transaction and the limit of
                courtesy renewals has been met
              </span>
            </v-tooltip>
            <div v-if="transactionType == 'Registration Renewal'">
              <v-select
                :readonly="readonly"
                menu-props="auto"
                :items="vehicleUseItems"
                v-model="vehicle.vehicleUseCode"
                label="Vehicle Use"
              />
            </div>
          </div>
        </div>
        <div
          v-if="
            !['Transaction', 'NewTransaction'].includes(
              $router.currentRoute.name
            )
          "
        >
          <v-select
            menu-props="auto"
            id="plateCounty"
            :readonly="readonly"
            v-model="registration.countyID"
            :items="convertForVSelect(countiesArray)"
            label="County of Registration"
          />
        </div>
        <div v-if="countyConfig.hasWheelTaxCities">
          <v-select
            :items="countyCities"
            :readonly="readonly"
            v-bind="attributes.wheelTax"
            label="Wheel Tax City"
            item-text="cityName"
            item-value="cityID"
            v-model="registration.wheelTaxCityID"
          />
        </div>
        <v-text-field
          v-bind="attributes.wheelTax"
          v-if="countyConfig.useCountyWheelTaxDecals"
          :readonly="readonly && transactionType !== 'Online Renewal'"
          label="County Decal Number"
          v-model.number="countyData.registration.countyWheelTaxDecalNo"
        />
        <v-text-field
          v-bind="attributes.wheelTax"
          v-if="countyConfig.useCityWheelTaxDecals"
          :readonly="readonly && transactionType !== 'Online Renewal'"
          label="City Decal Number"
          v-model.number="countyData.registration.cityWheelTaxDecalNo"
        />
      </div>
      <v-switch
        v-if="
          validation &&
          validation.requiresSalesTaxPaidProof &&
          !attributes.plateNo.disabled
        "
        v-bind="attributes.classTaxPaid"
        v-model="validation.salesTaxPaidProofProvided"
        label="Sales Tax Paid"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import starDatePicker from "@/components/nonPageComponents/starDatePicker";
import date from "@/mixins/date.mixin";
import mapForSelect from "@/mixins/mapForSelect.mixin.js";
import transaction from "@/mixins/transaction.mixin.js";
import dayjs from "dayjs";
import { plate } from "@/assets/js/defaultTransactionRequestObjects/defaults";
const transactionTypes = require("@/assets/jsonScaffolds/transactionTypes.json");

export default {
  mixins: [date, mapForSelect, transaction],
  components: { starDatePicker },
  props: {
    title: {
      type: String,
      default: () => {
        return "Registration";
      },
      required: false
    },
    transaction: {
      type: Object,
      default: () => {
        return {};
      }
    },
    registration: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    },
    attributes: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    },
    relevantPlateClasses: {
      type: Array,
      default: () => {
        return [];
      },
      required: true
    },
    readonly: { type: Boolean, default: false, required: false },
    vehicleUseItems: {
      type: Array,
      default: () => {
        return [];
      },
      required: false
    },
    vehicle: {
      type: Object,
      default: () => {
        return {};
      },
      required: false
    },
    countyData: Object,
    validation: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      plateError: { error: false, errorMessages: [] },
      autoCompletePlate: [],
      multipleReturnedPlates: false,
      platesList: [],
      issueYearArray: "",
      dateMenu: false,
      tableFields: [
        {
          label: "Issue Year",
          key: "issueYear",
          thStyle: { width: "125px" }
        },
        {
          label: "Class",
          key: "classCode",
          thStyle: { width: "100px" }
        },
        {
          label: "Make",
          key: "currentMake",
          thStyle: { width: "100px" }
        },
        {
          label: "Vehicle Year",
          key: "currentYear",
          thStyle: { width: "150px" }
        }
      ],
      selectedAutoAssignClass: ""
    };
  },
  methods: {
    clearErrors() {
      if (this.plate.plateNo != "") {
        this.plateError.error = false;
        this.plateError.errorMessages = "";
      } else {
        this.plateError.error = true;
        this.plateError.errorMessages = "Required";
      }
    },
    getAllowedDates(date) {
      // Allow all dates for these transactions
      if (!this.isEndOfMonthTransaction()) {
        return true;
      }
      // Only allow end of month dates for all else
      return dayjs(date).isSame(dayjs(date).endOf("month"), "day");
    },
    updateValidation() {
      if (!this.validation) return; // nothing to do

      if (this.plate.class) {
        this.validation.requiresSalesTaxPaidProof = this._.find(
          this.inventoryPlateClasses,
          {
            plateClassCode: this.plate.class
          }
        ).salesTaxRequired;
        this.validation.salesTaxPaidProofProvided = false;
      }
    },
    classSelect(clearYear = true, updateExpiration = true) {
      let platecheck = "";

      this.updateValidation();
      if (this._.has(this.registration, "registrationBeginDate"))
        delete this.registration.registrationBeginDate;
      if (
        updateExpiration &&
        !["Online Renewal", "Registration Renewal", "Replace Plate"].includes(
          this.transactionType
        )
      )
        this.registration.registrationExpiresDate = dayjs()
          .add(1, "year")
          .endOf("month")
          .hour(0)
          .minute(0)
          .second(0)
          .format();
      platecheck = this.plate.class;
      if (platecheck === "") {
        this.issueYearArray = "";
        if (this.registration.currentPlate != undefined)
          this.registration.currentPlate.class = "";
      } else {
        //check if plate is permanent
        for (let i = 0; i < this.inventoryPlateClasses.length; i++) {
          if (
            this.inventoryPlateClasses[i].plateClassCode == platecheck &&
            this.inventoryPlateClasses[i].expirationCode == "P"
          ) {
            this.registration.registrationExpiresDate = this.endOfTime.format();
            this.registration.registrationBeginDate = dayjs().format();
          }
        }
        //setting issue year array
        for (let i = 0; i < this.plateClasses.length; i++) {
          if (this.plateClasses[i].plateClassCode === platecheck) {
            this.issueYearArray = this.plateClasses[i].plateDates.map(
              plateDate => parseInt(plateDate.issueYear)
            );

            this.$forceUpdate(); //this is needed since we are replacing the whole array here
            break;
          }
        }
      }
      if (clearYear) {
        this.plate.issueYear = "";
      }
    },
    checkPlateSearch(arrayOfPlates) {
      if (!Array.isArray(arrayOfPlates)) arrayOfPlates = [arrayOfPlates];

      if (arrayOfPlates.length === 1) {
        const plate = arrayOfPlates[0];

        this.plate.class = plate.classCode;

        this.classSelect();
        this.plate.issueYear = plate.issueYear;

        if (
          ![
            plate.currentMake,
            plate.currentYear,
            plate.registrationExpiresDate
          ].includes(null)
        ) {
          // plate is assigned
          Object.assign(this.registration, {
            plateTransfer: true,
            currentMake: plate.currentMake,
            currentYear: plate.currentYear
          });

          if (!this.editExpirationDateDisabled) {
            if (plate.registrationExpiresDate.includes("T")) {
              plate.registrationExpiresDate = dayjs(
                plate.registrationExpiresDate.split("T")[0]
              ).format("YYYY-MM-DD");
            }

            this.registration.registrationExpiresDate =
              plate.registrationExpiresDate;
          }

          this.$root.$emit(
            "push-alert",
            "The entered plate will be transfered from its current vehicle"
          );
          this.isAssigned = true;
        }
        this.multipleReturnedPlates = false;
      } else if (arrayOfPlates.length > 1) {
        this.platesList = arrayOfPlates;
        this.multipleReturnedPlates = true;
      }
    },
    classErrors() {
      const errors = [];
      if (
        this.processObj.registration?.newRequiredClass &&
        this.plate.class !== this.processObj.registration?.newRequiredClass
      ) {
        errors.push(
          `${this.processObj.registration.newRequiredClass} new class required.`
        );
      }
      if ([undefined, null, ""].includes(this.plate.class)) {
        errors.push("Required");
      }

      if (
        this.transactionType === "Registration Renewal" &&
        this.countyConfig.limitClassChangeRenewals
      ) {
        if (
          (["1000", "1001"].includes(this.originalPlate?.class) &&
            !["1000", "1001"].includes(this.plate.class)) ||
          (this.plate.class !== this.originalPlate?.class &&
            !["1000", "1001"].includes(this.originalPlate?.class))
        ) {
          errors.push("Ineligible Class");
        }
      }

      return errors;
    },
    async checkPlate() {
      if (this.readonly) return;

      // remove transfer information
      Object.assign(this.registration, {
        plateTransfer: false,
        currentMake: undefined,
        currentYear: undefined
      });

      if (
        [undefined, null, ""].includes(this.plate.plateNo) &&
        !this.readonly
      ) {
        this.plateError = { error: true, errorMessages: ["Required"] };
        this.plate.issueYear = "";
        this.plate.class = "";
        return;
      }
      //dont validate vintage plate numbers
      if (this.plate.class == "3025") {
        return;
      }

      this.plateError = { error: false, errorMessages: [] };
      if (this.plate.plateNo === this.originalPlate.plateNo) {
        // change to currentPlate property
        Object.assign(this.plate, this.originalPlate);
        this.isAssigned = true;
      } else if (this.plate.plateNo != "") {
        // change to newPlate property
        this.isAssigned = false;

        let plates = [];
        try {
          plates = await this.$api.inventoryPlateSearch(this.plate.plateNo);
        } catch (e) {
          this.plate.class = "";
          this.plate.issueYear = "";
          return false;
        }
        this.checkPlateSearch(plates);
      }
    },
    isEndOfMonthTransaction() {
      const transactionName =
        transactionTypes[this.transaction.transactionType];
      return ![
        "Temporary Operators Permit",
        "Placard Transaction",
        "Farm Permit"
      ].includes(transactionName);
    },
    async autoAssignNextPlate() {
      if (this.selectedAutoAssignClass === "None") {
        this.plate.plateNo = "";
        this.plate.class = "";
        this.plate.issueYear = "";
        return;
      }
      const nextPlate = await this.$api.nextPlate(
        this.selectedAutoAssignClass,
        {
          404: () => {
            this.$store.dispatch("setGlobalAlertState", {
              title: "Error",
              description:
                "This worksation/user is not currently assigned any plates of this class",
              icon: "error"
            });
          }
        }
      );

      this.plate.plateNo = nextPlate.plateNo;
      this.checkPlate();
    }
  },
  computed: {
    ...mapGetters({
      transactionType: "transactionType",
      inventoryPlateClasses: "inventoryPlateClasses",
      plateClasses: "plateClasses",
      odometerBrands: "odometerBrands",
      vehicleUse: "vehicleUse",
      titleCodes: "titleCodes",
      fuelArray: "fuelTypes",
      countiesArray: "counties",
      bodyTypesArray: "bodyTypes",
      colorsArray: "vehicleColors",
      vehicleTypesArray: "vehicleTypes",
      makesArray: "makes",
      barcode: "barcode",
      BDPProps: "BDPProps",
      pendingTransaction: "pendingTransaction",
      processObj: "processObj",
      VTRSVehicleRecord: "VTRSVehicleRecord",
      countyConfig: "countyConfig",
      endOfTime: "endOfTime"
    }),
    isAssigned: {
      get() {
        return this.registration.currentPlate !== undefined;
      },
      set(isAssigned) {
        if (isAssigned) {
          this.registration.currentPlate = this.plate;
          delete this.registration.newPlate;
          if (["Lost/Stolen Plate or Decal"].includes(this.transactionType)) {
            this.transaction.isDecalReplacement = true;
          }
        } else {
          this.registration.newPlate = this.plate;
          delete this.registration.currentPlate;
          if (["Lost/Stolen Plate or Decal"].includes(this.transactionType)) {
            delete this.transaction.isDecalReplacement;
          }
        }
      }
    },
    verifyDate() {
      if (this.isEndOfMonthTransaction()) {
        return (
          dayjs(this.registration.registrationExpiresDate).isSame(
            dayjs(this.registration.registrationExpiresDate)
              .endOf("month")
              .format()
          ) ||
          dayjs(this.registration.registrationExpiresDate).isSame(
            dayjs(this.registration.registrationExpiresDate)
              .endOf("month")
              .hour(0)
              .minute(0)
              .second(0)
              .format()
          )
        );
      } else {
        return true;
      }
    },
    classSelectMap() {
      return Object.keys(this.relevantPlateClasses).map(key => {
        return {
          text:
            this.relevantPlateClasses[key].plateClassCode +
            " - " +
            this.relevantPlateClasses[key].plateClassDescription,
          value: this.relevantPlateClasses[key].plateClassCode
        };
      });
    },
    issueYearSelectMap() {
      return Object.keys(this.issueYearArray).map(key => {
        return {
          text: this.issueYearArray[key],
          value: this.issueYearArray[key]
        };
      });
    },
    plate() {
      return this.registration.newPlate || this.registration.currentPlate;
    },
    editExpirationDateDisabled() {
      return (
        (this.processObj.hasIncomplete &&
          this.processObj.courtestyRenewalsCount > 0) ||
        !!this.processObj.registration?.newRequiredClass ||
        this.processObj.requireNewMetal
      );
    },
    countyCities() {
      return [{ cityID: null, cityName: "None" }].concat(
        this.$store.getters.countyCities
      );
    },
    autoAssignClasses() {
      return ["None", ...this.countyConfig.autoAssignInventoryClasses];
    },
    displayAutoAssignField() {
      return (
        this.countyConfig.autoAssignInventoryClasses.length > 0 &&
        !this.readonly &&
        ![
          "Transfer Registration",
          "Reprint Decal",
          "Temporary Operators Permit",
          "Temporary Tag Only",
          "Transfer Registration to New",
          "Farm Permit"
        ].includes(this.transactionType) &&
        !this.transaction.isRebuild
      );
    }
  },
  watch: {
    barcode() {
      if (
        !["Transaction", "NewTransaction"].includes(
          this.$router.currentRoute.name
        )
      )
        return;

      if (
        this._.has(this.barcode.parses, "plate") &&
        !this.attributes.plateNo.disabled
      ) {
        this.registration.newPlate.plateNo = this.barcode.parses.plate.plateNo;
        this.registration.newPlate.class = this.barcode.parses.plate.plateClass;
      }

      this.checkPlate();
      this.classSelect();
    }
  },
  created() {
    this.updateValidation();
  },
  async mounted() {
    // store current VTRS plate
    if (this._.has(this.VTRSVehicleRecord, "registration"))
      this.originalPlate = this._.cloneDeep(
        this.VTRSVehicleRecord.registration.newPlate ||
          this.VTRSVehicleRecord.registration.currentPlate
      );
    else this.originalPlate = plate();
    if (
      this.plate.plateNo &&
      this.plate.plateNo !== this.originalPlate.plateNo &&
      this.transactionType !== "Online Renewal" &&
      !this.processObj.transaction.isDeficientFinish
    )
      this.checkPlate(); // eztag plate transfers currently lack plate info from /inquiry/search, so we get it here from /inventory/plates/

    if (
      this.registration.newPlate != undefined &&
      [undefined, null, ""].includes(this.registration.newPlate.plateNo) &&
      !this.readonly
    ) {
      this.plateError = { error: true, errorMessages: ["Required"] };
    }
    if (this.registration != undefined) {
      let platecheck = "";
      if (
        this.registration.currentPlate != undefined &&
        this.registration.currentPlate.class != undefined
      ) {
        platecheck = this.registration.currentPlate.class;
      } else {
        platecheck = this.registration.newPlate.class;
      }
      if (platecheck === "") {
        this.issueYearArray = [];
        if (this.registration.currentPlate != undefined)
          this.registration.currentPlate.class = "";
      } else {
        if (this.$store.getters.plateClasses != undefined) {
          for (let i = 0; i < this.$store.getters.plateClasses.length; i++) {
            if (
              this.$store.getters.plateClasses[i].plateClassCode === platecheck
            ) {
              this.issueYearArray = this.$store.getters.plateClasses[
                i
              ].plateDates.map(x => parseInt(x.issueYear));
            }
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
  flex-direction: row;
}
.titleSection {
  width: 20%;
}
.titleSection h3 {
  font-size: 20pt;
}

.leftInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.threeColumn {
  display: flex;
  margin-right: 4%;
  margin-left: 5%;
}
.threeColumn .leftInputs {
  margin-right: 25px;
}
.middleInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.threeColumn .middleInputs {
  margin-right: 25px;
}
.rightInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.fullInputWidth {
  margin-left: 20%;
  width: 80%;
}
.fullInputWidth > .rightInputs {
  padding-right: 5%;
}

.multiPlate-dialog {
  padding: 20px;
  text-align: center;
}

#class {
  width: 100%;
}

#issueYear {
  width: 100%;
}
</style>
