<template>
  <div>
    <div class="flex">
      <div class="titleSection">
        <h3>
          {{ title }}
        </h3>
      </div>
      <div
        v-if="_.has(ownership, 'nameCode') && !isPlacard"
        class="inputSection twoColumn"
      >
        <div class="rightInputs">
          <div class="flex">
            <v-select
              class="small-select"
              :items="[
                { text: 'One Name', value: '0' },
                { text: 'Two First & One Last Name', value: '1' },
                { text: 'Two First & Two Last Names', value: '2' },
                { text: 'Company / Business', value: '3' }
              ]"
              v-model="ownership.nameCode"
              v-bind="attributes.nameCode"
              :readonly="nameCodeIsReadOnly || readonly"
              label="Name Code"
            />
            <v-spacer />
            <v-switch
              v-if="!readonly"
              label="Show Fee Exceptions"
              v-model="showFeeExceptions"
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="randomizeButtonIsShown && !readonly"
                  v-on="on"
                  @click="$emit('randomizeButtonPress')"
                >
                  <v-icon>shuffle</v-icon>
                </v-btn>
              </template>
              <span>Randomize Owner Information</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div
        v-else-if="
          _.has(ownership, 'nameCode') &&
          isPlacard &&
          ownership.nameCode !== '0'
        "
      >
        <v-select
          :items="[
            { text: 'Owner 1', value: '0' },
            { text: 'Owner 2', value: '1' }
          ]"
          v-model="placardOwnerIndex"
          label="Owner Index"
        />
      </div>
    </div>
    <div
      v-if="
        owners.length > 0 &&
        (!isPlacard || ownership === undefined || nameCode === '0')
      "
      class="fullInputWidth"
    >
      <div class="rightInputs">
        <div class="flex">
          <div v-if="_.has(ownership, 'nameCode')" class="ownerLabel">
            <label>Owner 1</label>
          </div>
          <div>
            <div>
              <div
                v-if="
                  !_.has(ownership, 'nameCode') ||
                  (_.has(ownership, 'nameCode') && ownership.nameCode != 3)
                "
                class="flex owner-name"
              >
                <v-text-field
                  v-bind="
                    Object.assign(
                      {},
                      validate('.individualCustomer.lastName'),
                      attributes.ownerName
                    )
                  "
                  v-model="owners[0].individualCustomer.lastName"
                  @input="
                    owners[0].individualCustomer.lastName =
                      owners[0].individualCustomer.lastName.toUpperCase()
                  "
                  label="Last Name"
                  :readonly="readonly"
                />

                <v-text-field
                  v-bind="
                    Object.assign(
                      {},
                      validate('.individualCustomer.firstName'),
                      attributes.ownerName
                    )
                  "
                  v-model="owners[0].individualCustomer.firstName"
                  @input="
                    owners[0].individualCustomer.firstName =
                      owners[0].individualCustomer.firstName.toUpperCase()
                  "
                  :readonly="readonly"
                  label="First Name"
                />

                <v-text-field
                  v-bind="
                    Object.assign(
                      {},
                      validate('.individualCustomer.middleName'),
                      attributes.ownerName
                    )
                  "
                  v-model="owners[0].individualCustomer.middleName"
                  @input="
                    owners[0].individualCustomer.middleName =
                      owners[0].individualCustomer.middleName.toUpperCase()
                  "
                  :readonly="readonly"
                  label="Middle Name"
                />

                <v-text-field
                  v-bind="
                    Object.assign(
                      {},
                      validate('.individualCustomer.nameSuffix'),
                      attributes.ownerName
                    )
                  "
                  v-model="owners[0].individualCustomer.nameSuffix"
                  @input="
                    owners[0].individualCustomer.nameSuffix =
                      owners[0].individualCustomer.nameSuffix.toUpperCase()
                  "
                  :readonly="readonly"
                  label="Suffix"
                />
              </div>
              <div v-else id="businessName">
                <v-text-field
                  v-bind="
                    Object.assign(
                      {},
                      validate('.customerName'),
                      attributes.ownerName
                    )
                  "
                  v-model="owners[0].customerName"
                  @input="
                    owners[0].customerName =
                      owners[0].customerName.toUpperCase()
                  "
                  :readonly="readonly"
                  label="Business Name"
                />
              </div>
            </div>
            <template v-if="!isPlacard">
              <special-needs
                :owner="owners[0]"
                :readonly="readonly"
                :nameCode="nameCode"
              />
            </template>
          </div>
        </div>
        <div
          class="flex"
          v-if="
            _.has(ownership, 'nameCode') &&
            (ownership.nameCode == 1 || ownership.nameCode == 2) &&
            owners[1] != undefined
          "
        >
          <div class="ownerLabel">
            <label>Owner 2</label>
          </div>
          <div>
            <div class="flex owner-name">
              <v-text-field
                v-if="ownership.nameCode != 1"
                v-bind="
                  Object.assign(
                    {},
                    validate('.individualCustomer.lastName', owners1Errors),
                    attributes.ownerName
                  )
                "
                v-model="owners[1].individualCustomer.lastName"
                @input="
                  owners[1].individualCustomer.lastName =
                    owners[1].individualCustomer.lastName.toUpperCase()
                "
                :readonly="readonly"
                label="Last Name"
              />

              <v-text-field
                v-model="owners[1].individualCustomer.firstName"
                v-bind="
                  Object.assign(
                    {},
                    validate('.individualCustomer.firstName', owners1Errors),
                    attributes.ownerName
                  )
                "
                @input="
                  owners[1].individualCustomer.firstName =
                    owners[1].individualCustomer.firstName.toUpperCase()
                "
                label="First Name"
                :readonly="readonly"
              />

              <v-text-field
                v-model="owners[1].individualCustomer.middleName"
                v-bind="
                  Object.assign(
                    {},
                    validate('.individualCustomer.middleName', owners1Errors),
                    attributes.ownerName
                  )
                "
                @input="
                  owners[1].individualCustomer.middleName =
                    owners[1].individualCustomer.middleName.toUpperCase()
                "
                :readonly="readonly"
                label="Middle Name"
              />

              <v-text-field
                v-bind="
                  Object.assign(
                    {},
                    validate('.individualCustomer.nameSuffix', owners1Errors),
                    attributes.ownerName
                  )
                "
                v-model="owners[1].individualCustomer.nameSuffix"
                @input="
                  owners[1].individualCustomer.nameSuffix =
                    owners[1].individualCustomer.nameSuffix.toUpperCase()
                "
                label="Suffix"
                :readonly="readonly"
              />
            </div>

            <template v-if="!isPlacard">
              <special-needs
                :owner="owners[1]"
                :readonly="readonly"
                :nameCode="nameCode"
              />
            </template>
          </div>
        </div>
        <div class="flex" v-if="_.has(ownership, 'conjunctionCode')">
          <v-select
            v-bind="attributes.conjunctionCode"
            v-if="ownership.nameCode == 1 || ownership.nameCode == 2"
            :items="[
              { text: '', value: '0' },
              { text: 'And', value: '1' },
              { text: 'Or', value: '2' }
            ]"
            :error-messages="ownership.conjunctionCode == 0 ? 'Required' : ''"
            :error="ownership.conjunctionCode == 0"
            :readonly="readonly"
            v-model="ownership.conjunctionCode"
            label="Conjunction"
          />
        </div>
        <starDatePicker
          v-if="
            ['Re-Title Only', 'Re-Title and Register'].includes(
              transactionType
            ) &&
            ownership.nameCode === '0' &&
            _.has(ownership, 'spouseDateOfDeath')
          "
          v-model="ownership.spouseDateOfDeath"
          label="Spouse Date of Death"
          :disabled="readonly"
          :displayFormat="BDPProps.displayFormat"
          :textFieldProps="BDPProps.TextField"
          :datePickerProps="BDPProps.DatePicker"
          :menuProps="BDPProps.Menu"
          :allowEmpty="true"
          modelFormat="YYYY-MM-DDTHH:mm:ssZ"
        />
        <div class="flex">
          <v-text-field
            v-if="!isPlacard"
            v-bind="validate('.inCareOf')"
            @input="owners[0].inCareOf = owners[0].inCareOf.toUpperCase()"
            v-model="owners[0].inCareOf"
            label="In Care Of"
            :readonly="readonly"
          />
        </div>
        <fee-exceptions
          v-if="!readonly"
          :processObj="processObj"
          :showFeeExceptions="showFeeExceptions"
        />
      </div>
    </div>
    <div v-else>
      <div class="fullInputWidth">
        <div class="rightInputs">
          <div class="flex">
            <v-text-field
              v-bind="
                Object.assign(
                  {},
                  validate('.individualCustomer.lastName'),
                  attributes.ownerName
                )
              "
              v-model="owners[placardOwnerIndex].individualCustomer.lastName"
              @input="
                owners[placardOwnerIndex].individualCustomer.lastName =
                  owners[
                    placardOwnerIndex
                  ].individualCustomer.lastName.toUpperCase()
              "
              label="Last Name"
              :readonly="readonly"
            />

            <v-text-field
              v-bind="
                Object.assign(
                  {},
                  validate('.individualCustomer.firstName'),
                  attributes.ownerName
                )
              "
              v-model="owners[placardOwnerIndex].individualCustomer.firstName"
              @input="
                owners[placardOwnerIndex].individualCustomer.firstName =
                  owners[
                    placardOwnerIndex
                  ].individualCustomer.firstName.toUpperCase()
              "
              :readonly="readonly"
              label="First Name"
            />

            <v-text-field
              v-bind="
                Object.assign(
                  {},
                  validate('.individualCustomer.middleName'),
                  attributes.ownerName
                )
              "
              v-model="owners[placardOwnerIndex].individualCustomer.middleName"
              @input="
                owners[placardOwnerIndex].individualCustomer.middleName =
                  owners[
                    placardOwnerIndex
                  ].individualCustomer.middleName.toUpperCase()
              "
              :readonly="readonly"
              label="Middle Name"
            />

            <v-text-field
              v-bind="
                Object.assign(
                  {},
                  validate('.individualCustomer.nameSuffix'),
                  attributes.ownerName
                )
              "
              v-model="owners[placardOwnerIndex].individualCustomer.nameSuffix"
              @input="
                owners[placardOwnerIndex].individualCustomer.nameSuffix =
                  owners[
                    prePlacardOwner
                  ].individualCustomer.nameSuffix.toUpperCase()
              "
              :readonly="readonly"
              label="Suffix"
            />
          </div>
          <template v-if="!isPlacard">
            <special-needs
              :owner="owners[placardOwnerIndex]"
              :readonly="readonly"
              :nameCode="nameCode"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import feeExceptions from "@/components/nonPageComponents/FeeExceptions";
import specialNeeds from "@/components/nonPageComponents/specialNeeds";
import schemaValidation from "@/mixins/schemaValidation.mixin.js";
import transaction from "@/mixins/transaction.mixin.js";
import starDatePicker from "@/components/nonPageComponents/starDatePicker";
import { mapGetters } from "vuex";

export default {
  components: { starDatePicker, feeExceptions, specialNeeds },
  mixins: [schemaValidation, transaction],
  props: {
    processObj: {
      type: Object,
      required: true
    },
    owners: {
      type: Array,
      default: () => {
        return [];
      },
      required: true
    },
    title: {
      type: String,
      default: () => {
        return "Registrant";
      }
    },
    attributes: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    },
    isFromBackButton: { type: Boolean, required: false, default: () => false },
    ownership: { type: Object, required: false },
    randomizeButtonIsShown: {
      type: Boolean,
      default: () => {
        return false;
      },
      required: false
    },
    readonly: { type: Boolean, default: false, required: false },
    isPlacard: { type: Boolean, default: false, required: false }
  },
  data() {
    return {
      owners1Errors: [],
      showFeeExceptions: false,
      placardOwnerIndex: this.$store.getters.placardOwnerIndex
    };
  },
  computed: {
    ...mapGetters({
      transactionType: "transactionType",
      BDPProps: "BDPProps",
      countyConfig: "countyConfig"
    }),
    nameCodeIsReadOnly() {
      return (
        this.transactionType != "Temporary Operators Permit" &&
        this.isRegistration
      );
    },
    nameCode() {
      return this.ownership?.nameCode;
    }
  },
  mounted() {
    if (!this.readonly) {
      this.getValidationSchema(
        this.$store.getters.apiHostAndPort + "/customer.schema.json"
      ).then(json => {
        this.schemaJSON = json;
        this.loadSchemaValidator().then(() => {
          this.validateSchema(this.owners[0]);
        });
      });

      if (
        !this.isPlacard &&
        (!this._.isEmpty(this.processObj.ownership?.vaGrantNo) ||
          this.processObj.titleTaxExemption !== undefined ||
          this.processObj.registration?.firefighterInd ||
          this.processObj.registration?.rescueInd)
      ) {
        this.showFeeExceptions = true;
      }

      if (this.isPlacard && this.nameCode !== "0" && !this.isFromBackButton) {
        this.placardOwnerIndex = "0";
      }
    }
  },
  methods: {
    async validateSchemaOwners1(dataToValidate) {
      if (typeof this.validateAJV === "function") {
        const valid = this.validateAJV(this._.cloneDeep(dataToValidate));
        if (!valid) {
          this.owners1Errors = this.validateAJV.errors;
        }
      }
    }
  },
  watch: {
    placardOwnerIndex: {
      handler(val) {
        this.$store.commit("placardOwnerIndex", val);
      }
    },
    "owners.0": {
      handler(val) {
        if (!this.readonly) this.validateSchema(val);
      },
      deep: true
    },
    "owners.1": {
      handler(val) {
        if (!this.readonly) this.validateSchemaOwners1(val);
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.small-select {
  max-width: 24%;
}
.ownerLabel {
  min-width: 80px;

  label {
    white-space: nowrap;
    margin-bottom: 25px;
    margin-right: 10px;
  }
}
.flex {
  display: flex;
  flex-direction: row;
}
.titleSection {
  width: 20%;
}
.titleSection h3 {
  font-size: 20pt;
}
.inputSection {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 4%;
}

.leftInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.twoColumn {
  width: 100%;
}
.twoColumn .leftInputs {
  margin-right: 10%;
}
.twoColumn .rightInputs {
  margin-right: 5%;
}
.threeColumn {
  display: flex;
  margin-right: 4%;
  margin-left: 5%;
}
.threeColumn .leftInputs {
  margin-right: 25px;
}
.middleInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.threeColumn .middleInputs {
  margin-right: 25px;
}
.rightInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.fullInputWidth {
  margin-left: 20%;
  width: 80%;
}
.fullInputWidth > .rightInputs {
  padding-right: 5%;
}

.no-left {
  margin-left: 0;
}

.panel .selectInput select {
  margin-top: -4px;
}
.selectInput input {
  margin-top: -4px;
  margin-bottom: 20px;
}

::v-deep .selectInput .vdp-datepicker input {
  margin-top: -4px;
  margin-bottom: 20px;
}

input {
  width: 100%;
}
</style>
