<template>
  <div id="vehicleData" v-if="hasInitialized">
    <div
      v-if="
        transactionType === 'Online Renewal' &&
        ![null, undefined].includes(existingRenewalImage)
      "
    >
      <imageScanner
        :existingImage="existingRenewalImage"
        :showScanControls="false"
        :readOnly="true"
      ></imageScanner>
      <hr />
    </div>
    <div
      v-if="sectionVisibility.title && _.has(processObj, 'vehicle')"
      id="vinSlide"
    >
      <div class="flex">
        <div class="titleSection">
          <h3 id="vinJump">Title</h3>
        </div>
        <div class="inputSection twoColumn">
          <div
            class="leftInputs"
            v-if="transactionType != 'Reassign Registration'"
          >
            <div
              class="mso-slider"
              v-show="
                isNewOwner &&
                [
                  'Title And Registration',
                  'Title Only',
                  'Transfer Registration to New'
                ].includes(transactionType)
              "
            >
              <v-switch
                :readonly="readOnly"
                label="Untitled"
                v-model="msoVehicle"
              ></v-switch>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle</v-icon>
                </template>
                <span>MSO</span>
              </v-tooltip>
            </div>

            <template
              v-if="
                processObj.title != undefined &&
                (readOnly || transactionType == 'Restore Vehicle Record')
              "
            >
              <v-text-field
                label="Title Number"
                readonly
                :value="processObj.title.titleNo"
              />
            </template>

            <v-text-field
              v-if="!['Correction of Title'].includes(this.transactionType)"
              v-bind="validate('.title.formerTitleNo')"
              label="Former Title Number"
              :readonly="readOnly"
              :disabled="msoVehicle"
              v-model="processObj.title.formerTitleNo"
            />

            <template
              v-if="!['Correction of Title'].includes(this.transactionType)"
            >
              <v-select
                :readonly="readOnly"
                menu-props="auto"
                v-bind="validate('.title.formerTitleState')"
                :disabled="msoVehicle"
                v-model="processObj.title.formerTitleState"
                :items="states"
                label="Former Title State"
                item-value="abbr"
                item-text="name"
              />
            </template>
            <star-date-picker
              v-if="!['Correction of Title'].includes(this.transactionType)"
              v-model="processObj.title.formerIssueDate"
              label="Former Issuance Date"
              :disabled="readOnly || msoVehicle"
              :displayFormat="BDPProps.displayFormat"
              :textFieldProps="BDPProps.TextField"
              :datePickerProps="BDPProps.DatePicker"
              :menuProps="BDPProps.Menu"
              modelFormat="YYYY-MM-DDTHH:mm:ssZ"
            />

            <template
              v-if="
                _.has(processObj, 'vehicle') &&
                transactionType != 'Reassign Registration'
              "
            >
              <v-select
                :readonly="readOnly"
                menu-props="auto"
                v-bind="validate('.vehicle.newUsedCode')"
                :disabled="msoVehicle"
                id="newUsedField"
                v-model="processObj.vehicle.newUsedCode"
                :items="[
                  { value: 'U', text: 'Used' },
                  { value: 'N', text: 'New' }
                ]"
                label="New/Used"
              />
            </template>

            <template
              v-if="
                processObj.title != undefined &&
                (readOnly || transactionType == 'Restore Vehicle Record')
              "
            >
              <v-select
                :readonly="readOnly"
                menu-props="auto"
                v-bind="validate('.title.statusID')"
                :items="convertForVSelect(titleCodes)"
                v-model="processObj.title.statusID"
                label="Title Status"
              />
            </template>

            <v-autocomplete
              clearable
              hide-no-data
              label="Exemption"
              :readonly="readOnly"
              :items="titleTaxExemptionsArray"
              item-text="name"
              item-value="val"
              @blur="checkExempt()"
              :search-input.sync="exemptName"
            />

            <v-text-field
              v-model.number="processObj.title.outStateTaxCredit"
              label="Sales Tax Credit"
              type="number"
              @wheel="$event.target.blur()"
              min="0"
              v-if="
                _.has(processObj, 'title.outStateTaxCredit') &&
                !['Correction of Title'].includes(this.transactionType)
              "
              v-bind="validate('.title.outStateTaxCredit')"
              :readonly="readOnly"
            />

            <v-text-field
              :readonly="readOnly"
              type="number"
              @wheel="$event.target.blur()"
              min="0"
              v-bind="validate('.title.dealerCollectedTax')"
              v-model.number="processObj.title.dealerCollectedTax"
              v-if="
                _.has(processObj, 'title.dealerCollectedTax') &&
                !['Correction of Title'].includes(this.transactionType)
              "
              label="Dealer Collected Tax"
            />
          </div>

          <div v-if="_.has(processObj, 'vehicle')" class="rightInputs">
            <v-select
              :readonly="readOnly"
              menu-props="auto"
              id="odometerIndicatorField"
              v-bind="validate('.vehicle.odometerTypeCode')"
              v-model="processObj.vehicle.odometerTypeCode"
              :items="convertForVSelect(odometerBrands, false, false)"
              label="Odometer Indicator"
            />

            <v-text-field
              v-if="
                processObj.vehicle.odometerTypeCode ===
                odometerBrandCodes['Actual Mileage']
              "
              :readonly="readOnly"
              id="odometerField"
              type="number"
              @wheel="$event.target.blur()"
              min="0"
              v-bind="validate('.vehicle.odometerReading')"
              @blur="updateFairMarketValue()"
              v-model="processObj.vehicle.odometerReading"
              label="Odometer"
            />

            <v-text-field
              :readonly="readOnly"
              v-model="processObj.title.salePrice"
              type="number"
              @wheel="$event.target.blur()"
              min="0"
              v-bind="validate('.title.salePrice')"
              v-if="
                _.has(processObj, 'title.salePrice') &&
                !['Correction of Title'].includes(this.transactionType)
              "
              label="Sale Price"
            />

            <v-switch
              v-if="transactionType == 'Title Only'"
              v-model="hasRevenueSticker"
              label="Add TDR Sticker"
            />

            <v-text-field
              tabindex="-1"
              readonly
              type="text"
              label="Fair Market Value"
              v-model="fairMarketValue"
              v-if="!['Correction of Title'].includes(this.transactionType)"
            />

            <v-text-field
              v-if="_.has(processObj, 'title.warrantyCost')"
              type="number"
              @wheel="$event.target.blur()"
              min="0"
              :readonly="readOnly"
              label="Warranty Cost"
              v-model.number="processObj.title.warrantyCost"
              v-bind="validate('.title.warrantyCost')"
            />
            <v-select
              menu-props="auto"
              :readonly="readOnly"
              label="Tax State"
              :disabled="
                processObj.title.outStateTaxCredit == null ||
                processObj.title.outStateTaxCredit == ''
              "
              v-bind="validate('.title.outStateTaxState')"
              :items="states"
              item-value="abbr"
              item-text="abbr"
              v-if="
                _.has(processObj, 'title.outStateTaxState') &&
                !['Correction of Title'].includes(this.transactionType)
              "
              v-model="processObj.title.outStateTaxState"
            />

            <v-text-field
              :readonly="readOnly"
              type="number"
              @wheel="$event.target.blur()"
              min="0"
              label="Trade In Amount"
              v-if="
                _.has(processObj, 'title.tradeAllowance') &&
                !['Correction of Title'].includes(this.transactionType)
              "
              v-model.number="processObj.title.tradeAllowance"
              v-bind="validate('.title.tradeAllowance')"
            />
          </div>
        </div>
      </div>

      <div
        v-if="_.has(processObj, 'vehicle.allTitleBrands')"
        class="fullInputWidth"
      >
        <v-switch
          v-model="showTitleBrands"
          label="Show Title Brands"
          v-if="transactionType != 'Reassign Registration'"
        />

        <template
          v-if="processObj.vehicle.titleBrands != undefined && showTitleBrands"
        >
          <v-checkbox
            v-for="titleBrand in processObj.vehicle.allTitleBrands"
            :disabled="readOnly || titleBrand.isBound"
            v-model="titleBrand.isSelected"
            :key="titleBrand.titleBrandID"
            :label="titleBrand.description"
          />
        </template>
      </div>
      <hr />
    </div>

    <div class="flex" v-if="showVin">
      <div class="titleSection">
        <h3 id="vehicleJump">Vehicle</h3>
      </div>
      <div class="inputSection">
        <v-text-field
          label="VIN"
          :value="processObj.vehicle.vin"
          :readonly="!vinIsEditable"
          @keydown="handleVinKeyDown"
        />
      </div>
    </div>

    <div
      v-if="sectionVisibility.vehicle && _.has(processObj, 'vehicle')"
      id="vehicleSlide"
    >
      <div class="flex">
        <div class="titleSection">
          <h3 id="vehicleJump">Vehicle</h3>
        </div>
        <div class="flex twoColumn">
          <div class="inputSection">
            <div class="leftInputs">
              <v-text-field
                :readonly="!vinIsEditable"
                id="vinField"
                @blur="updateFairMarketValue()"
                type="text"
                v-model="processObj.vehicle.vin"
                v-bind="handelVinValidation()"
                v-debounce:500="populateVINData"
                @input="
                  processObj.vehicle.vin = processObj.vehicle.vin.toUpperCase()
                "
                @keydown="event => handleVinKeyDown(event)"
                label="VIN"
              />

              <v-select
                :readonly="readOnly"
                menu-props="auto"
                v-bind="validate('.vehicle.majorColorCode')"
                v-if="_.has(processObj, 'vehicle')"
                v-model="processObj.vehicle.majorColorCode"
                :items="colorsArray"
                item-text="value"
                item-value="key"
                label="Color"
              />

              <v-select
                :readonly="readOnly"
                menu-props="auto"
                v-bind="validate('.vehicle.minorColorCode')"
                v-model="processObj.vehicle.minorColorCode"
                :items="colorsArray"
                item-text="value"
                item-value="key"
                label="Minor Color"
              />
            </div>

            <div class="rightInputs">
              <div
                class="selectInput"
                v-if="_.has(processObj, 'ownership.purchaseDate')"
              >
                <star-date-picker
                  v-if="!readOnly && purchaseDateIsShown"
                  v-model="purchaseDate"
                  label="Purchase Date"
                  :displayFormat="BDPProps.displayFormat"
                  :textFieldProps="{
                    ...BDPProps.TextField,
                    clearable: true
                  }"
                  :datePickerProps="{
                    ...BDPProps.DatePicker,
                    allowedDates: date => isDateBeforeNow(date)
                  }"
                  :menuProps="BDPProps.Menu"
                  modelFormat="MM/DD/YYYY"
                />
              </div>

              <v-select
                :readonly="readOnly"
                menu-props="auto"
                v-bind="validate('.vehicle.vehicleUseCode')"
                :items="convertForVSelect(vehicleUse)"
                v-model="processObj.vehicle.vehicleUseCode"
                label="Vehicle Use"
              />

              <v-select
                :readonly="readOnly"
                menu-props="auto"
                v-if="isUndercoverTransaction"
                v-bind="validate('.undercoverVehicleUseCode')"
                :items="convertForVSelect(vehicleUse)"
                v-model="processObj.undercoverVehicleUseCode"
                label="Undercover Vehicle Use"
              />

              <v-select
                v-if="['Temporary Tag Only'].includes(transactionType)"
                :readonly="readOnly"
                menu-props="auto"
                v-bind="validate('.vehicle.odometerTypeCode')"
                v-model="processObj.vehicle.odometerTypeCode"
                :items="convertForVSelect(odometerBrands, false, false)"
                label="Odometer Indicator"
              />

              <v-text-field
                v-if="
                  ['Temporary Tag Only'].includes(transactionType) &&
                  processObj.vehicle.odometerTypeCode ===
                    odometerBrandCodes['Actual Mileage']
                "
                :readonly="readOnly"
                type="number"
                @wheel="$event.target.blur()"
                min="0"
                v-bind="validate('.vehicle.odometerReading')"
                @blur="updateFairMarketValue()"
                v-model="processObj.vehicle.odometerReading"
                label="Odometer"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="fullInputWidth">
        <span v-show="!readOnly">
          <b>Make:</b> {{ processObj.vehicle.makeCode }} <b>Model:</b>
          {{ processObj.vehicle.modelCode }} <b>Year:</b>
          {{ processObj.vehicle.modelYear }}
          <br />
        </span>
        <v-switch
          v-if="!isUndercoverTransaction && !readOnly"
          v-model="showOwner"
          label="Leased"
        />
        <v-switch
          v-if="!readOnly"
          label="Edit Vehicle Info"
          v-model="showMoreVehicleInfo"
        />

        <div
          v-if="processObj.hasOwnProperty('vehicle')"
          class="flex twoColumn"
          v-show="showMoreVehicleInfo || readOnly"
        >
          <div class="leftInputs">
            <v-switch
              label="VIN Override"
              v-show="!readOnly"
              v-if="vinIsEditable"
              v-model="vinOverride"
            />

            <makeSelect
              :readonly="readOnly"
              v-model="processObj.vehicle.makeCode"
              :data="$store.getters.makes"
              @input="
                $store.commit(
                  'processObj',
                  JSON.parse(JSON.stringify(processObj))
                )
              "
              v-bind="validate('.vehicle.makeCode')"
            ></makeSelect>

            <v-text-field
              :readonly="readOnly"
              id="modelField"
              maxlength="3"
              v-bind="validate('.vehicle.modelCode')"
              v-model="processObj.vehicle.modelCode"
              @input="
                processObj.vehicle.modelCode =
                  processObj.vehicle.modelCode.toUpperCase();
                $store.commit('processObj', processObj);
              "
              label="Model"
            />

            <v-select
              :readonly="readOnly"
              menu-props="auto"
              id="bodyField"
              v-bind="validate('.vehicle.bodyTypeCode')"
              :items="convertForVSelect(bodyTypesArray)"
              v-model="processObj.vehicle.bodyTypeCode"
              label="Body"
            />

            <v-select
              menu-props="auto"
              :readonly="readOnly"
              id="yearField"
              v-bind="validate('.vehicle.modelYear')"
              v-model="processObj.vehicle.modelYear"
              @change="$store.commit('processObj', processObj)"
              :items="modelYears"
              label="Year"
            />

            <v-select
              :readonly="readOnly"
              menu-props="auto"
              id="fuelTypeField"
              v-bind="validate('.vehicle.fuelTypeCode')"
              v-model="processObj.vehicle.fuelTypeCode"
              :items="fuelArray"
              item-text="FuelTypeDescription"
              item-value="FuelTypeCode"
              label="Fuel Type"
            />

            <v-select
              :readonly="readOnly"
              menu-props="auto"
              id="autoDrivingField"
              v-bind="validate('.vehicle.autoDrivingInd')"
              v-model="processObj.vehicle.autoDrivingInd"
              :items="autoDrivingArray"
              item-text="value"
              item-value="key"
              label="Auto Driving Ind"
            />
          </div>
          <div class="rightInputs">
            <v-select
              :readonly="readOnly"
              menu-props="auto"
              v-bind="validate('.vehicle.vehicleTypeCode')"
              id="vehicleTypeField"
              label="Vehicle Type"
              :items="convertForVSelect(vehicleTypesArray)"
              v-model="processObj.vehicle.vehicleTypeCode"
            />

            <template
              v-if="
                !['Transaction', 'NewTransaction'].includes(
                  $router.currentRoute.name
                ) || processObj.vehicle.vehicleUseCode != 'P'
              "
            >
              <v-text-field
                :readonly="readOnly"
                v-bind="validate('.vehicle.grossVehicleWeight')"
                type="number"
                @wheel="$event.target.blur()"
                min="0"
                v-model="processObj.vehicle.grossVehicleWeight"
                label="Gross Weight"
              />

              <v-text-field
                :readonly="readOnly"
                v-bind="validate('.vehicle.axleCount')"
                type="number"
                @wheel="$event.target.blur()"
                min="0"
                maxlength="1"
                label="Axles"
                v-model="processObj.vehicle.axleCount"
              />

              <v-text-field
                :readonly="readOnly"
                maxlength="1"
                v-bind="validate('.vehicle.weightClassSeatCode')"
                type="number"
                @wheel="$event.target.blur()"
                min="0"
                v-model="processObj.vehicle.weightClassSeatCode"
                label="Class/Seat"
              />

              <v-text-field
                :readonly="readOnly"
                v-bind="validate('.registration.operatingZone')"
                v-if="processObj.hasOwnProperty('registration')"
                v-model="processObj.registration.operatingZone"
                label="Zone"
                maxlength="5"
              />

              <v-text-field
                :readonly="readOnly"
                v-bind="validate('.vehicle.companyVehicleNo')"
                v-model="processObj.vehicle.companyVehicleNo"
                label="Company Vehicle No"
              />
            </template>
          </div>
        </div>
      </div>
      <hr />
    </div>

    <div v-if="sectionVisibility.placard && _.has(processObj, 'placard')">
      <placardBlock :placard="processObj.placard" :readonly="readOnly" />
      <hr />
    </div>
    <div
      v-if="
        sectionVisibility.plate &&
        (_.has(processObj, 'registration.newPlate') ||
          _.has(processObj, 'registration.currentPlate'))
      "
      id="plateJump"
    >
      <registrationBlock
        :readonly="readOnly"
        :relevantPlateClasses="relevantPlateClasses"
        :transaction="processObj.transaction"
        :vehicle="processObj.vehicle"
        :countyData="processObj.countyData"
        :vehicleUseItems="convertForVSelect(vehicleUse)"
        :registration="processObj.registration"
        :attributes="attributes"
        :validation="validation"
      />
      <hr />
    </div>
    <div
      v-if="_.has(processObj, 'undercoverRegistration.newPlate')"
      id="plateJump"
    >
      <registrationBlock
        :readonly="readOnly"
        :title="'Undercover Registration'"
        :transaction="processObj.transaction"
        :relevantPlateClasses="relevantPlateClasses"
        :registration="processObj.undercoverRegistration"
        :attributes="attributes"
        :validation="validation"
      />
      <hr />
    </div>
    <div id="owner_drop" v-if="sectionVisibility.owner">
      <div id="lessor" v-if="showOwner && transactionType !== 'New Placard'">
        <div class="flex">
          <div class="titleSection">
            <h3>Lessor</h3>
          </div>
          <div class="inputSection twoColumn">
            <div class="rightInputs">
              <div class="flex">
                <v-text-field
                  :readonly="readOnly"
                  v-bind="validate('.lessor.customerName')"
                  v-if="processObj.lessor != undefined"
                  v-model="processObj.lessor.customerName"
                  @input="
                    processObj.lessor.customerName =
                      processObj.lessor.customerName.toUpperCase()
                  "
                  label="Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="fullInputWidth">
          <div class="rightInputs">
            <vue-smarty-streets
              :uppercase="true"
              :readonly="readOnly"
              :showCounties="true"
              :addressObj="processObj.lessor.physicalAddress"
            ></vue-smarty-streets>
          </div>
        </div>
        <hr />
      </div>
      <div id="registrant">
        <registrantBlock
          :isPlacard="_.has(processObj, 'placard')"
          :readonly="readOnly"
          :title="
            showOwner && transactionType !== 'New Placard'
              ? 'Lessee'
              : 'Registrant / Owner'
          "
          :attributes="attributes"
          :ownership="processObj.ownership"
          :owners="processObj.owners"
          :processObj="processObj"
          :isFromBackButton="isFromBackButton"
        />
      </div>
      <hr />
    </div>
    <template
      v-if="
        sectionVisibility.registrantAddress &&
        _.has(processObj, 'owners') &&
        processObj.owners.length > 0
      "
    >
      <div
        id="registrantAddress"
        v-if="[undefined, null].includes(processObj.currentAddress)"
      >
        <div class="flex">
          <div class="titleSection">
            <h3>Physical Address</h3>
          </div>
          <div class="inputSection twoColumn">
            <div class="rightInputs">
              <vue-smarty-streets
                :uppercase="true"
                :readonly="readOnly"
                :showCounties="true"
                :addressObj="processObj.owners[0].physicalAddress"
              ></vue-smarty-streets>
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <div
          v-for="address in [newPhysicalAddress, processObj.currentAddress]"
          :key="address === newPhysicalAddress"
        >
          <div class="flex">
            <div class="titleSection">
              <h3>
                {{ address === newPhysicalAddress ? "New" : "Current" }}
                Physical Address
              </h3>
              <v-switch
                v-if="address === newPhysicalAddress"
                v-model="useNewPhysicalAddress"
                label="use this address"
              />

              <v-switch
                v-else
                v-model="useCurrentPhysicalAddress"
                label="use this address"
              />
            </div>
            <div class="inputSection twoColumn">
              <div class="rightInputs">
                <vue-smarty-streets
                  :uppercase="true"
                  :readonly="readOnly"
                  :showCounties="true"
                  :addressObj="address"
                ></vue-smarty-streets>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="fullInputWidth">
        <v-switch
          v-if="!readOnly"
          v-model="showMailing"
          label="Show Mailing Address"
          color="red"
        />
      </div>
      <hr />
    </template>
    <div
      id="mailingAddress"
      v-if="
        _.has(processObj, 'owners') &&
        processObj.owners.length > 0 &&
        sectionVisibility.registrantAddress &&
        showMailing
      "
    >
      <div class="flex">
        <div class="titleSection">
          <h3>Mailing Address</h3>
        </div>
        <div class="inputSection twoColumn">
          <div
            v-if="processObj.owners[0].mailingAddress != undefined"
            class="rightInputs"
          >
            <vue-smarty-streets
              :uppercase="true"
              :readonly="readOnly"
              :addressObj="processObj.owners[0].mailingAddress"
            ></vue-smarty-streets>
          </div>
        </div>
      </div>
      <hr />
    </div>

    <div v-if="_.has(processObj, 'undercoverOwners')" class="undercover">
      <div id="undercoverRegistrant">
        <registrantBlock
          :readonly="readOnly"
          :randomizeButtonIsShown="true"
          @randomizeButtonPress="
            randomizeUndercoverOwnerData(
              processObj.undercoverOwnership.nameCode
            )
          "
          :title="'Undercover Registrant / Owner'"
          :attributes="attributes"
          :ownership="processObj.undercoverOwnership"
          :owners="processObj.undercoverOwners"
          :processObj="processObj"
          :isFromBackButton="isFromBackButton"
        />
      </div>
      <hr />

      <div class="flex">
        <div class="titleSection">
          <h3>Undercover Physical Address</h3>
        </div>
        <div class="inputSection twoColumn">
          <div class="rightInputs">
            <vue-smarty-streets
              :uppercase="true"
              :readonly="readOnly"
              :showCounties="true"
              :addressObj="processObj.undercoverOwners[0].physicalAddress"
            ></vue-smarty-streets>
          </div>
        </div>
      </div>
      <div class="fullInputWidth">
        <v-switch
          v-if="!readOnly"
          v-model="showMailingUndercover"
          label="Show Mailing Address"
        />
        >
      </div>
      <hr />
      <div v-show="showMailingUndercover">
        <div class="flex">
          <div class="titleSection">
            <h3>Undercover Mailing Address</h3>
          </div>
          <div class="inputSection twoColumn">
            <div
              v-if="processObj.undercoverOwners[0].mailingAddress != undefined"
              class="rightInputs"
            >
              <vue-smarty-streets
                :uppercase="true"
                :readonly="readOnly"
                :addressObj="processObj.undercoverOwners[0].mailingAddress"
              ></vue-smarty-streets>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>

    <div
      v-if="
        sectionVisibility.registrantAddress &&
        _.has(processObj, 'owners') &&
        processObj.owners.length > 0
      "
      id="contactInfo"
    >
      <div class="flex">
        <div class="titleSection">
          <h3>Contact Info</h3>
        </div>
        <div class="inputSection twoColumn">
          <div class="leftInputs">
            <VuePhoneNumberInput
              v-model="customerPhone"
              @update="processPhoneNo($event)"
              maxlength="30"
              :disabled="readOnly"
            />
          </div>
          <div class="rightInputs">
            <div class="flex">
              <v-text-field
                :error="
                  processObj.owners[0].emailRenewalInd == '1' &&
                  ['', undefined, null].includes(
                    processObj.owners[0].customerEMail
                  )
                "
                :error-messages="
                  processObj.owners[0].emailRenewalInd == '1' &&
                  ['', undefined, null].includes(
                    processObj.owners[0].customerEMail
                  )
                    ? 'Required'
                    : ''
                "
                v-model="processObj.owners[0].customerEMail"
                :readonly="readOnly"
                label="Email"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="fullInputWidth">
        &nbsp;
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-checkbox
              v-model="processObj.owners[0].emailRenewalInd"
              :true-value="1"
              :false-value="0"
              :disabled="readOnly"
            >
              <template slot="label">
                <span>Go Green</span>
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>
            </v-checkbox>
          </template>
          <span> Only send digital renewal notices </span>
        </v-tooltip>
      </div>
      <hr />
    </div>
    <div
      id="lienholderJump"
      v-if="
        (transactionType !== 'New Placard' &&
          _.has(processObj, 'liens') &&
          processObj.liens.length > 0) ||
        !liensAreReadonly
      "
    >
      <div class="flex">
        <div class="titleSection">
          <h3>Lienholders</h3>
        </div>
      </div>
      <div class="fullInputWidth">
        <div id="lienholderMainBox">
          <div v-if="showLiens && !liensAreReadonly">
            <Draggable
              group="people"
              @start="drag = true"
              @end="drag = false"
              @change="onDrop"
              v-bind="dragOptions"
              :list="processObj.liens"
            >
              <lienholder
                @changeOrder="lienholderChangeOrder($event)"
                :readOnly="liensAreReadonly"
                :lienholder="value"
                :index="index"
                :processObj="processObj"
                v-for="(value, index) in processObj.liens"
                :key="index"
                class="draggable-item"
              >
              </lienholder>
            </Draggable>
          </div>
          <div v-else>
            <lienholder
              v-for="(value, index) in processObj.liens"
              :key="index"
              @changeOrder="lienholderChangeOrder($event)"
              :readOnly="liensAreReadonly"
              :lienholder="value"
              :index="index"
              :processObj="processObj"
            >
            </lienholder>
          </div>
        </div>
      </div>
      <div v-show="!liensAreReadonly" class="fullInputWidth">
        <div class="center">
          <v-btn color="primary" v-on:click="addLien">+ LienHolder</v-btn>
        </div>
      </div>
      <hr />
    </div>

    <div v-if="sectionVisibility.fees" id="fees">
      <div class="flex">
        <div class="titleSection">
          <h3>Fees</h3>
        </div>
      </div>
      <fees
        v-if="
          _.has(processObj, 'transaction.fees') &&
          sectionVisibility.fees === true
        "
        :fees="processObj.transaction.fees"
        @overrideFees="$emit('overrideFees', $event)"
        @overrideIsValid="$emit('overrideIsValid', $event)"
        class="fullInputWidth"
        :readonly="readOnly"
      /><!-- todo> remove online reneal exception when previous fees block (above) is removed -->
    </div>
    <div
      v-if="
        sectionVisibility.comments && processObj.transaction.transactionType
      "
      id="comments"
    >
      <div class="flex">
        <div class="titleSection">
          <h3>Comments</h3>
        </div>
      </div>
      <template
        v-if="
          _.has(processObj, 'transaction.comments') &&
          processObj.transaction.comments.length > 0
        "
      >
        <div
          class="fullInputWidth"
          v-for="(value, index) in processObj.transaction.comments"
          :key="index"
        >
          <div class="panel">
            <b>Comment {{ index + 1 }}</b>
            <v-textarea
              :id="'comment' + (index + 1)"
              :value="value"
              :disabled="true"
              type="text"
              outlined
            />
          </div>
        </div>
      </template>
      <template
        v-if="
          _.has(processObj, 'tempComments') &&
          processObj.tempComments.length > 0
        "
      >
        <div
          class="fullInputWidth"
          v-for="(value, index) in processObj.tempComments"
          :key="index"
        >
          <div class="panel">
            <v-btn @click="deleteComment(index)" class="commentClose" icon>
              <v-icon>close</v-icon>
            </v-btn>
            <v-switch v-model="value.isGlobalComment" label="Global Comment" />

            <v-textarea
              :id="'comment' + (index + 1)"
              v-model="value.comment"
              type="text"
              outlined
            />
          </div>
        </div>
      </template>
      <div v-show="!readOnly" class="fullInputWidth">
        <div class="center">
          <v-btn color="primary" @click="addComment">+ Comment</v-btn>
        </div>
      </div>
      <hr />
    </div>
    <div v-if="_.has(mostRecentTrans) || isEditTrans" id="plateJump">
      <div class="flex">
        <div class="titleSection">
          <h3 id="vinJump">Maintenance</h3>
        </div>
      </div>
      <maintenanceEdit :mostRecentTrans="mostRecentTrans" />
      <hr />
    </div>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import { states } from "@/assets/js/constants";
import { mapGetters } from "vuex";
import dayjs from "dayjs";

const transactionTypes = require("@/assets/jsonScaffolds/transactionTypes.json");
let transactionNames;
const visibility = require("@/assets/jsonConstants/sectionVisibility.json");
const vehicleTypesWithoutOdometer = require("@/assets/jsonConstants/vehicleTypesWithoutOdometer.json");
import address from "@/assets/js/defaultTransactionRequestObjects/address";

// components
import lienholder from "@/components/nonPageComponents/SingleLienholder";
import vueSmartyStreets from "@/components/nonPageComponents/vue-smarty-streets";
import makeSelect from "@/components/nonPageComponents/makeSelect";
import imageScanner from "@/components/nonPageComponents/ImageScanner";
import registrationBlock from "@/components/vehicleDisplayComponents/registrationBlock";
import maintenanceEdit from "@/components/nonPageComponents/maintenanceEdit";
import registrantBlock from "@/components/vehicleDisplayComponents/registrantBlock";
import feesComponent from "@/components/vehicleDisplayComponents/fees";
import placardBlock from "@/components/vehicleDisplayComponents/placardBlock";
import VuePhoneNumberInput from "vue-phone-number-input";
import starDatePicker from "@/components/nonPageComponents/starDatePicker";
// mixins
import date from "@/mixins/date.mixin";
import mapForSelect from "@/mixins/mapForSelect.mixin";
import schemaValidation from "@/mixins/schemaValidation.mixin.js";
import feesMixin from "@/mixins/fees.mixin";

const yrs = [];
for (let i = "1900"; i < new Date().getFullYear() + 2; i++) {
  yrs.push(i);
}
yrs.reverse();
const defaultModelYears = yrs;

export default {
  props: {
    processObj: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    },
    showIdScan: { type: Boolean, default: true, required: false },
    mostRecentTrans: {
      type: Object,
      default: () => {
        return {};
      },
      required: false
    },
    isEditTrans: { type: Boolean, default: false, required: false },
    readOnly: { type: Boolean, default: false, required: false },
    isTitleIssuingTrans: { type: Boolean, default: false, required: false },
    isNewOwner: { default: false }, //Doesn't seem like this is needed and/or is redundant.
    parentShowMoreVehicleInfo: { default: false },
    attributes: {
      type: Object,
      default: () => ({
        classTaxPaid: {},
        plateNo: {},
        nameCode: {},
        class: {},
        issueYear: {},
        plateExp: {},
        plateCounty: {},
        wheelTax: {},
        conjunctionCode: {},
        ownerName: {},
        deafInd: {}
      })
    },
    validation: {
      type: Object,
      default: null
    },
    isFromBackButton: {
      type: Boolean,
      default: false
    }
  },
  mixins: [date, mapForSelect, schemaValidation, feesMixin],
  components: {
    registrantBlock,
    lienholder,
    Draggable,
    vueSmartyStreets,
    makeSelect,
    imageScanner,
    registrationBlock,
    maintenanceEdit,
    fees: feesComponent,
    placardBlock,
    VuePhoneNumberInput,
    starDatePicker
  },
  data() {
    return {
      hasInitialized: false,
      allTitleBrands: this.$store.getters.allTitleBrands,
      fairMarketValue: "0",
      existingRenewalImage: undefined,
      states: states,
      hasBackButtonLogicRun: false,
      showTitleBrands: false,
      msoVehicle: false,
      showVin: false,
      titleTaxExemptionsArray: [],
      otherTaxExemptionValue: "",
      exemptName: "",
      showOwner: false,
      showMoreVehicleInfo: this.parentShowMoreVehicleInfo,
      showMailing: false,
      showMailingUndercover: false,
      showLiens: true,
      isValidVinFormat: true,
      purchaseDate: "",
      newPhysicalAddress: this._.has(
        this.processObj,
        "owners[0].physicalAddress"
      )
        ? this.processObj.owners[0].physicalAddress
        : address(),
      useCurrentPhysicalAddress: false,
      useNewPhysicalAddress: true,
      drag: false,
      modelYears: defaultModelYears,
      vinModelYears: defaultModelYears,
      vinOverride: false,
      fees: this.processObj.transaction.fees,
      customerPhone: this._.get(this.processObj, "owners[0].customerPhone")
    };
  },
  methods: {
    setPurchaseDateFromTransactionType() {
      this.purchaseDate =
        this.processObj.ownership?.purchaseDate &&
        (!["New Owner", "New Owner Title Only"].includes(
          this.transactionType
        ) ||
          this.isEditTrans ||
          this.doBackButtonLogic)
          ? dayjs(this.processObj.ownership.purchaseDate).format("MM/DD/YYYY")
          : ["Title Only", "Title And Registration"].includes(
              this.transactionType
            )
          ? dayjs().format("MM/DD/YYYY")
          : "";
    },
    async randomizeUndercoverOwnerData(nameCode) {
      let ownerdata;
      try {
        ownerdata = await this.$api.randomCustomer(
          this.$store.getters.countyId,
          nameCode
        );
      } catch (e) {
        console.error(e);
        return;
      }

      this.processObj.undercoverOwners = ownerdata;
      const inCust = this.processObj.undercoverOwners[0].individualCustomer;
      if (!this._.has(inCust, "nameSuffix") || inCust.nameSuffix == undefined) {
        inCust["nameSuffix"] = "";
      }
      if (!this._.has(inCust, "middleName") || inCust.middleName == undefined) {
        inCust["middleName"] = "";
      }
      if (
        !this._.has(this.processObj.undercoverOwners[0], "inCareOf") ||
        this.processObj.undercoverOwners[0].inCareOf == undefined
      ) {
        this.processObj.undercoverOwners[0]["inCareOf"] = "";
      }

      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    isDateBeforeNow(date) {
      return dayjs(date).diff(dayjs()) <= 0;
    },
    checkExempt() {
      const taxExemptCode = this.titleTaxExemptionsArray.find(
        ({ name }) => name === this.exemptName
      );
      if (taxExemptCode) {
        this.processObj.title.taxExemptCode = Number(taxExemptCode.val);
      } else if (![undefined, null, ""].includes(this.exemptName)) {
        this.processObj.title.taxExemptCode = Number(
          this.otherTaxExemptionValue
        );
      } else {
        this.processObj.title.taxExemptCode = 0; //No tax exemption entered(Will NOT exempt tax)
      }
      this.$store.commit("processObj", this._.cloneDeep(this.processObj));
    },
    async lienholderChangeOrder(index) {
      this.sectionVisibility.lienholder = false;
      await this.$nextTick(() => {
        this.sectionVisibility.lienholder = true;
      });
      this.$nextTick(() => {
        document.getElementById("precedence" + index).focus();
      });
    },
    onDrop() {
      const precedences = this.processObj.liens.map(lien =>
        parseInt(lien.lienPrecedenceNo)
      );
      precedences.sort();

      for (const x in precedences) {
        const prec = precedences[x];

        this.processObj.liens[x].lienPrecedenceNo = prec;
      }
    },
    addComment() {
      const newComment = {
        comment: "",
        isGlobalComment: true
      };
      this.processObj.tempComments.push(newComment);

      this.$nextTick(() => {
        document
          .getElementById("comment" + this.processObj.tempComments.length)
          .focus();
      });
    },
    deleteComment(index) {
      this.processObj.tempComments.splice(index, 1);
    },
    addLien() {
      this.showLiens = false;
      let newPrec = 0;
      for (let i = 0; i < this.processObj.liens.length; i++) {
        if (
          this.processObj.liens[i].lienholder.businessCustomer.businessName ==
          ""
        ) {
          this.$store.dispatch("setGlobalAlertState", {
            title: "There is an empty lienholder",
            description:
              "You must fill in the lienholder fields before adding another.",
            icon: "error"
          });
          this.showLiens = true;
          return false;
        }
        if (parseInt(this.processObj.liens[i].lienPrecedenceNo) > newPrec)
          newPrec = parseInt(this.processObj.liens[i].lienPrecedenceNo);
      }
      newPrec += 1;
      const lienSetup = {
        lienID: 0,
        lienDate: "",
        vehicleID: this.processObj.vehicle.vehicleID,
        lienPrecedenceNo: newPrec,
        lienholder: {
          physicalAddress: {},
          customerType: "B",
          businessCustomer: { businessName: "" }
        },
        AddedLien: true
      };
      this.processObj.liens.push(lienSetup);
      this.$forceUpdate();
      this.$nextTick(() => {
        this.showLiens = true;
      });
    },
    scanLicense(type) {
      this.$emit("scanLicense", type);
    },
    async vinDecode(vin) {
      return await fetch("https://api.bisonline.com/vin/decode/" + vin).then(
        async data => await data.json()
      );
    },
    async initializeModelYears() {
      try {
        const vehicleInfo = await this.vinDecode(this.processObj.vehicle.vin);

        this.vinModelYears = vehicleInfo.modelYear.map(year => parseInt(year));
        this.modelYears = this.vinModelYears;
      } catch (e) {
        console.error("Vin Decode Failed");
      } finally {
        /** If the existing model year is not in the list of model years, its
         * likely that the user intends to VIN override. Common when going back from
         * the checkout screen.
         */
        const modelYear = this.processObj.vehicle.modelYear;
        if (modelYear != "" && !this.modelYears.includes(modelYear)) {
          this.vinOverride = true;
        }
      }
    },
    async populateVINData(removeBadVIN = false) {
      this.processObj.vehicle.modelCode = "";
      this.processObj.vehicle.makeCode = "";
      this.processObj.vehicle.fuelTypeCode = "";
      this.processObj.vehicle.vehicleTypeCode = "";
      this.processObj.vehicle.modelYear = "";
      this.processObj.vehicle.bodyTypeCode = "";
      this.processObj.vehicle.grossVehicleWeight = "";

      if (this.processObj.vehicle.vin === "") {
        this.modelYears = defaultModelYears;
        return;
      }

      try {
        this.isValidVinFormat = true;
        const vehicleInfo = await this.vinDecode(this.processObj.vehicle.vin);

        if (vehicleInfo.errorText !== undefined) {
          throw vehicleInfo;
        }

        let modelKey = "";

        if (vehicleInfo.ncicModel == "" || vehicleInfo.ncicModel == undefined) {
          modelKey = vehicleInfo.model;
        } else {
          modelKey = vehicleInfo.ncicModel;
        }
        if (modelKey.length > 3) {
          modelKey = modelKey.substring(0, 3);
        }

        this.vinModelYears = vehicleInfo.modelYear.map(year => parseInt(year));
        this.processObj.vehicle.modelCode = modelKey;
        this.processObj.vehicle.makeCode = vehicleInfo.ncicMake;
        this.processObj.vehicle.fuelTypeCode = vehicleInfo.tnclerkFuelType;
        this.processObj.vehicle.vehicleTypeCode =
          vehicleInfo.tnclerkVehicleType;
        this.processObj.vehicle.modelYear = this.vinModelYears[0];
        this.processObj.vehicle.bodyTypeCode = vehicleInfo.tnclerkBodyType;
        const weight = vehicleInfo.grossWgt.replace(/[^0-9-]/g, "");

        if (
          !["", null, undefined].includes(weight) &&
          weight.match(/\d*-\d*/g).length > 0
        ) {
          this.processObj.vehicle.grossVehicleWeight = weight.replace(
            /^[^-]*-/g,
            ""
          );
        } else {
          this.processObj.vehicle.grossVehicleWeight = weight;
        }

        this.$store.commit(
          "processObj",
          JSON.parse(JSON.stringify(this.processObj))
        );
      } catch (e) {
        if (e.errorText === "Invalid length") {
          this.isValidVinFormat = false;
        }

        this.vinModelYears = defaultModelYears;

        if (removeBadVIN === true) {
          this.processObj.vehicle.vin = "";
        }
      } finally {
        this.modelYears = this.vinModelYears;
      }
    },
    async getFairMarketValue() {
      if (this.processObj.vehicle.vin === "") return;

      const fairMarketValue = await this.$api.getFairMarketValue(
        this.processObj.vehicle.vin,
        this.processObj.vehicle.odometerReading,
        { 404: () => {} }
      );

      return "$" + fairMarketValue.default;
    },
    handleVinKeyDown(event) {
      const regex = /^\w*$/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },
    async updateFairMarketValue() {
      if (this.processObj.title !== undefined) {
        this.fairMarketValue = await this.getFairMarketValue();
      }
    },
    handelVinValidation() {
      const errors = this.validate(".vehicle.vin");
      if (!this.isValidVinFormat && !this.vinOverride) {
        if (errors.errorMessages) {
          errors.errorMessages.push("Incorrect digit check");
        } else {
          errors.errorMessages = ["Incorrect digit check"];
        }
      }
      return errors;
    },
    handleFeeIconClick(fee) {
      if (fee.originalFeeAmount === fee.feeAmount) {
        fee.feeAmount = 0;
      } else {
        fee.feeAmount = fee.originalFeeAmount;
      }
      this.$forceUpdate(); // todo> this wasn't necessary before. may be worth fixing root reactivity problem
      this.$emit("feeChange"); // todo> temporary alternative to a fee watcher since reactivity is failing
    },
    processPhoneNo(event) {
      if (event.formattedNumber != null) {
        this.processObj.owners[0].customerPhone = event.formattedNumber.replace(
          /\D/g,
          ""
        );
      }
    }
  },
  mounted() {
    if (
      this.processObj.transaction !== undefined &&
      [
        "Title And Registration",
        "Title Only",
        "Transfer Registration to New"
      ].includes(this.transactionType) &&
      [undefined, null, ""].includes(this.processObj.title.formerTitleNo) &&
      this.processObj.transaction.isDeficientFinish
    ) {
      this.msoVehicle = true;
    }
    this.hasInitialized = true;
  },
  watch: {
    transactionType() {
      this.setPurchaseDateFromTransactionType();
    },
    purchaseDate(val) {
      if (this._.isEmpty(val)) {
        this.processObj.ownership.purchaseDate = "";
      } else if (
        dayjs(this.processObj.ownership.purchaseDate).format("MM/DD/YYYY") !==
        val
      ) {
        this.processObj.ownership.purchaseDate = this.getTimestamp(
          new Date(val)
        );
      }
    },
    exemptName() {
      if (![undefined, null, ""].includes(this.exemptName)) {
        this.exemptName = this.exemptName.toUpperCase();
      }
    },
    showMoreVehicleInfo() {
      if (this.showMoreVehicleInfo != this.parentShowMoreVehicleInfo)
        this.$emit("showMoreVehicleInfoChanged", this.showMoreVehicleInfo);
    },
    parentShowMoreVehicleInfo() {
      if (this.showMoreVehicleInfo != this.parentShowMoreVehicleInfo)
        this.showMoreVehicleInfo = this.parentShowMoreVehicleInfo;
    },
    msoVehicle() {
      if (
        this.processObj.vehicle !== undefined &&
        this.processObj.title !== undefined
      ) {
        if (this.msoVehicle) {
          this.processObj.title.formerTitleNo = "";
          this.processObj.title.formerTitleState = "";
          this.processObj.title.formerIssueDate = null;
          this.processObj.vehicle.newUsedCode = "N";
        }
      }
    },
    useCurrentPhysicalAddress(newUseCurrentPhysicalAddress) {
      this.useNewPhysicalAddress = !newUseCurrentPhysicalAddress;
      if (newUseCurrentPhysicalAddress)
        this.processObj.owners[0].physicalAddress =
          this.processObj.currentAddress;
    },
    useNewPhysicalAddress(newUseNewPhysicalAddress) {
      this.useCurrentPhysicalAddress = !newUseNewPhysicalAddress;
      if (newUseNewPhysicalAddress)
        this.processObj.owners[0].physicalAddress = this.newPhysicalAddress;
    },
    barcode() {
      if (
        !["Transaction", "NewTransaction"].includes(
          this.$router.currentRoute.name
        )
      )
        return;
      if (this._.has(this.barcode.parses, "id")) {
        const barParse = this.barcode.parses.id;
        const owner = this.processObj.owners[0];
        if (
          !this.showOwner &&
          this.processObj.ownership.nameCode != "3" &&
          this.sectionVisibility.registrant &&
          this.sectionVisibility.owner
        ) {
          Object.assign(owner.individualCustomer, {
            firstName: barParse.firstName,
            lastName: barParse.lastName,
            middleName: barParse.middleName
          });
          Object.assign(owner.physicalAddress, {
            address1: barParse.streetAddress,
            city: barParse.city,
            state: barParse.state,
            zip: barParse.zip
          });
          this.$forceUpdate();
        } else if (
          this.showOwner &&
          (this.processObj.lessor.customerName == "" ||
            this.processObj.lessor.customerName == undefined) &&
          this.sectionVisibility.registrant &&
          this.sectionVisibility.owner
        ) {
          this.processObj.lessor.customerName =
            this.barcode.parses.id.firstName +
            " " +
            this.barcode.parses.id.middleName +
            " " +
            this.barcode.parses.id.lastName;
          Object.assign(this.processObj.lessor.physicalAddress, {
            address1: barParse.streetAddress,
            city: barParse.city,
            state: barParse.state,
            postalCode: barParse.zip
          });
          this.$forceUpdate();
        } else if (
          this.showOwner &&
          this.processObj.lessor.customerName != "" &&
          this.sectionVisibility.registrant &&
          this.sectionVisibility.owner
        ) {
          Object.assign(owner.individualCustomer, {
            firstName: barParse.firstName,
            lastName: barParse.lastName,
            middleName: barParse.middleName
          });
          Object.assign(owner.physicalAddress, {
            address1: barParse.streetAddress,
            city: barParse.city,
            state: barParse.state,
            zip: barParse.zip
          });
          this.$forceUpdate();
        }
      } else if (this._.has(this.barcode.parses, "vin")) {
        if (this._.has(this, "processObj.vehicle.vin")) {
          this.processObj.vehicle.vin = this.barcode.parses.vin;
          this.populateVINData();
        }
      }
    },
    processObj: {
      handler(val) {
        if (!this.readOnly) this.validateSchema(val);
      },
      deep: true
    },
    vinOverride(newFlag) {
      this.processObj.vehicle.forcedVINInd = newFlag;
      if (newFlag == true) {
        this.modelYears = defaultModelYears;
      } else {
        this.modelYears = this.vinModelYears;
      }

      if (!this.modelYears.includes(this.processObj.vehicle.modelYear)) {
        this.processObj.vehicle.modelYear = "";
      }
    },
    "processObj.vehicle.allTitleBrands": {
      deep: true,
      handler(allTitleBrands) {
        if (
          !this._.has(this.processObj, "vehicle") ||
          !Array.isArray(allTitleBrands)
        )
          return;

        this.processObj.vehicle.titleBrands = [];
        for (let i = 0; i < allTitleBrands.length; i++) {
          if (allTitleBrands[i].isSelected)
            this.processObj.vehicle.titleBrands.push({
              titleBrandID: allTitleBrands[i].titleBrandID
            });
        }
      }
    },
    "processObj.vehicle.modelYear": {
      deep: true,
      handler(year) {
        if (
          [
            "Title And Registration",
            "Title Only",
            "Temporary Operators Permit"
          ].includes(this.transactionType) &&
          year != "" &&
          new Date().getFullYear() - year >= 10
        ) {
          this.processObj.vehicle.odometerTypeCode =
            this.odometerBrandCodes["No Brand"];
        }
      }
    },
    "processObj.ownership.spouseDateOfDeath"(spouseDateOfDeathString) {
      if ([null, undefined].includes(spouseDateOfDeathString)) {
        return;
      }

      const oneYearAgo = dayjs().subtract("1", "year");
      const spouseDateOfDeath = dayjs(
        spouseDateOfDeathString,
        this.isoDateTimeFormat
      );
      if (spouseDateOfDeath.isValid()) {
        const isSurvivingSpouse = spouseDateOfDeath.isAfter(oneYearAgo);

        this.processObj.transaction.isSurvivingSpouse = isSurvivingSpouse;
        this.processObj.transaction.transactionType = isSurvivingSpouse
          ? transactionNames["Surviving Spouse"]
          : transactionNames[this.transactionType];
      }
    },
    "processObj.vehicle.vehicleTypeCode"() {
      if (
        this._.get(
          vehicleTypesWithoutOdometer,
          this.processObj.vehicle?.vehicleTypeCode
        )
      ) {
        this.processObj.vehicle.odometerTypeCode = "1";
      }
    },
    "processObj.vehicle.vehicleUseCode"() {
      if (this.processObj.vehicle?.vehicleUseCode === "P") {
        this._.merge(this.processObj.vehicle, {
          grossVehicleWeight: "",
          axleCount: 0,
          weightClassSeatCode: "",
          companyVehicleNo: ""
        });

        this.processObj.registration.operatingZone = "";
      }
    }
  },
  computed: {
    ...mapGetters({
      isoDateTimeFormat: "isoDateTimeFormat",
      titleTaxExemptionsObject: "titleTaxExemptReasons",
      transactionType: "transactionType",
      inventoryPlateClasses: "inventoryPlateClasses",
      odometerBrands: "odometerBrands",
      odometerBrandCodes: "odometerBrandCodes",
      vehicleUse: "vehicleUse",
      titleCodes: "titleCodes",
      fuelArray: "fuelTypes",
      bodyTypesArray: "bodyTypes",
      colorsArray: "vehicleColors",
      autoDrivingArray: "autoDrivingTypes",
      vehicleTypesArray: "vehicleTypes",
      makesArray: "makes",
      simpleSearch: "simpleSearch",
      barcode: "barcode",
      BDPProps: "BDPProps",
      vin: "vin",
      isAdvancedSearch: "isAdvancedSearch"
    }),
    doBackButtonLogic() {
      return this.isFromBackButton && !this.hasBackButtonLogicRun;
    },
    vinIsEditable() {
      const vinEditableTrans = [
        "Correction of Title",
        "Restore Vehicle Record",
        "TDR Sticker",
        "Farm Permit",
        "Transfer Registration to New",
        "Title And Registration",
        "EZ Tag",
        "Undercover Registration",
        "Title Only",
        "Registration Only",
        "Temporary Tag Only",
        "Reassign Registration",
        "Undercover Registration"
      ].includes(this.transactionType);

      return (
        (!this.isEditTrans && !this.readOnly && vinEditableTrans) ||
        (this.isEditTrans &&
          [
            "Title Only",
            "Title And Registration",
            "Correction of Title"
          ].includes(this.transactionType))
      );
    },
    liensAreReadonly() {
      if (this.isEditTrans || this.processObj.transaction.isDeficientFinish) return false;
      if (this.transactionType !== "Restore Vehicle Record") {
        return (
          this.readOnly ||
          !this.isTitleIssuingTrans ||
          this.transactionType === "Duplicate Title"
        );
      }
      return false;
    },
    purchaseDateIsShown() {
      return [
        "Transfer Registration to New",
        "Re-Title and Register",
        "Re-Title Only",
        "Restore Vehicle Record",
        "Correction of Title",
        "Title And Registration",
        "Title Only",
        "New Owner",
        "New Owner Title Only"
      ].includes(this.transactionType);
    },
    plateClasses() {
      let plateClasses = this.$store.getters.plateClasses;

      if (this.transactionType == "Farm Permit") {
        plateClasses = plateClasses.filter(plateClass => {
          return [
            "1300",
            "1301",
            "1302",
            "1303",
            "1304",
            "1305",
            "1306",
            "1307",
            "1308",
            "1309"
          ].includes(plateClass.plateClassCode);
        });
      } else if (
        [
          "Temporary Operators Permit",
          "Title With Temporary Tag",
          "Temporary Tag Only"
        ].includes(this.transactionType)
      ) {
        plateClasses = plateClasses.filter(plateClass => {
          return ["1200"].includes(plateClass.plateClassCode);
        });
      }

      return plateClasses;
    },
    dragOptions() {
      return {
        ghostClass: "ghost"
      };
    },
    isUndercoverTransaction() {
      const undercoverTransactions = ["Undercover Registration"];
      return undercoverTransactions.includes(this.transactionType);
    },
    relevantPlateClasses() {
      if (
        this.processObj.transaction != undefined &&
        this.processObj.transaction.transactionType === "FP"
      ) {
        return this.inventoryPlateClasses.filter(plateClass => {
          return plateClass.plateTypeID === 31;
        });
      }

      return this.plateClasses;
    },
    sectionVisibility() {
      if (this.processObj.transaction.transactionType == "")
        return visibility[""];
      const transactionType =
        transactionTypes[this.processObj.transaction.transactionType];
      return visibility[transactionType];
    },
    // The revenueSticker key indicates there is a revenueStickerNumber associated
    // from the backend
    hasRevenueSticker: {
      get() {
        return this.processObj.revenueSticker !== undefined;
      },
      set(hasRevenueSticker) {
        if (hasRevenueSticker) {
          this.processObj.revenueSticker = {
            revenueStickerNo: ""
          };
        } else {
          delete this.processObj.revenueSticker;
        }
      }
    }
  },
  async created() {
    transactionNames = this._.invert(transactionTypes);

    if (![null, undefined].includes(this.processObj.supportingDocumentID)) {
      this.$api
        .getExistingImage(this.processObj.supportingDocumentID, {
          404: () => {
            this.$store.dispatch("setGlobalAlertState", {
              title: "Image Not Found",
              minWidth: "350px",
              description:
                "An image reference Id exists for this transaction, but no matching image was found.",
              actions: [
                {
                  text: "Okay",
                  handler: () => this.$store.dispatch("hideGlobalAlert"),
                  color: "primary"
                }
              ]
            });
          }
        })
        .then(res => {
          this.existingRenewalImage = res.document;
        });
    }

    if (this.titleTaxExemptionsObject != undefined) {
      Object.keys(this.titleTaxExemptionsObject).forEach(key => {
        if (this.titleTaxExemptionsObject[key] != "OTHER") {
          this.titleTaxExemptionsArray.push({
            val: key,
            name: this.titleTaxExemptionsObject[key]
          });
        } else {
          this.otherTaxExemptionValue = key;
        }
      });
    }
    if (this.doBackButtonLogic) {
      if (
        this.titleTaxExemptionsObject != undefined &&
        ![null, undefined, ""].includes(this.processObj.title?.taxExemptCode)
      ) {
        const exemptionIndex = this.titleTaxExemptionsArray.findIndex(
          exemption =>
            Number(exemption.val) ===
            Number(this.processObj.title.taxExemptCode)
        );
        this.exemptName = this.titleTaxExemptionsArray[exemptionIndex].name;
      }
      if (
        this.processObj.vehicle !== undefined &&
        this.processObj.title !== undefined
      ) {
        //issue #324 -- Identify if model is MSO
        if (
          this.processObj.title.formerTitleNo == "" &&
          this.processObj.title.formerTitleState == "" &&
          this.processObj.vehicle.newUsedCode == "N"
        ) {
          this.msoVehicle = true;
        }
      }
    }

    if (!this.readOnly) {
      this.getValidationSchema(
        this.$store.getters.apiHostAndPort + "/request.schema.json"
      ).then(json => {
        this.schemaJSON = json;
        this.loadSchemaValidator().then(() => {
          this.validateSchema(this.processObj);
        });
      });
    }

    this.setPurchaseDateFromTransactionType();
    this.hasBackButtonLogicRun = true;

    //defaulting name code

    this.updateFairMarketValue();

    //this sets initial show of mailing address and owner
    if (
      this.processObj.lessor !== undefined &&
      this.processObj.lessor.customerName != undefined &&
      this.processObj.lessor.customerName != ""
    ) {
      this.showOwner = true;
    }

    if (!this._.isEqual(this.processObj.owners[0].mailingAddress, address())) {
      this.showMailing = true;
    }

    //adding this to show the fields if vin is blank
    if (
      this.processObj.vehicle != undefined &&
      (this.processObj.vehicle.vin == undefined ||
        this.processObj.vehicle.vin == "")
    )
      this.showMoreVehicleInfo = true;
    if (
      this.processObj.ownership != undefined &&
      !this._.has(this.processObj, "ownership.purchaseDate")
    ) {
      this.processObj.ownership.purchaseDate = "";
    }

    if (this.processObj?.vehicle?.forcedVINInd) {
      this.vinOverride = true;
    }

    /** if this is a new transaction, fill in the VIN field if the search query is a valid vin */
    if (
      [
        "Title And Registration",
        "Title Only",
        "Registration Only",
        "Temporary Tag Only"
      ].includes(this.transactionType) &&
      !this.isEditTrans
    ) {
      if (
        this.processObj.vehicle.vin === "" &&
        this.simpleSearch.trim().length === 17
      ) {
        this.processObj.vehicle.vin = this.simpleSearch.trim();
      }

      if (
        this.isAdvancedSearch &&
        this.processObj.vehicle.vin === "" &&
        !["", null, undefined].includes(this.vin)
      ) {
        this.processObj.vehicle.vin = this.vin;
      }

      this.populateVINData(true);
    } else if (
      this._.has(this.processObj, "vehicle.vin") &&
      this.processObj.vehicle.vin != ""
    ) {
      this.initializeModelYears();
    }
  }
};
</script>

<style scoped lang="scss">
.mso-slider {
  display: inherit;
}
.ghost {
  opacity: 0;
}
.commentClose {
  float: right;
  margin: 0px;
}
.draggable-item {
  background-color: var(--primary);
  cursor: move;
}
#businessName {
  width: 100%;
}
.ownersBlock input {
  margin-right: 5px;
}
.ownersBlock .flex {
  align-items: center;
}

.no-left {
  margin-left: 0;
}

.panel .selectInput select {
  margin-top: -4px;
}
.selectInput input {
  margin-top: -4px;
  margin-bottom: 20px;
}

::v-deep .selectInput .vdp-datepicker input {
  margin-top: -4px;
  margin-bottom: 20px;
}
.panel .selectInput label {
  margin-bottom: 0px;
}
.panel .selectInput select {
  width: 100%;
}

.panel hr {
  margin: 40px 0px 40px 0px;
}

.preloader-scan {
  position: relative;
  top: 80px;
  left: 0;
  right: 0;
  max-width: 200px;
  display: table;
  margin: 0 auto;
  height: 100%;
  text-align: center;
}

.preloader-scan ul {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  list-style-type: none;
  text-align: center;
}

.preloader-scan li {
  display: inline-block;
  width: 2px;
  height: 50px;
  background-color: #444;
}

.modal {
  display: block;
  background-color: white;
  z-index: 9998;
  width: 50%;
  height: 500px;
  position: sticky;
  top: 15%;
  left: 25%;
  bottom: 4%;
  border-radius: 10px;
  border: 1px gray solid;
}

.modalClose {
  float: right;
  margin-right: 2%;
}

.modalBody {
  text-align: center;
  padding: 3%;
}

.modalButtons button {
  width: 200px;
}

#detailsRight {
  margin-right: 235px;
}

input[type="text"] {
  text-transform: uppercase;
}

input {
  text-transform: uppercase !important;
}

.noPrint {
  display: inline;
}

#left {
  width: 250px;
}

#modalClose {
  font-size: 30px;
  float: right;
  margin: 15px;
}

@media print {
  .modal {
    width: 100%;
    height: 100%;
    margin: 0;
    left: 0;
  }

  #modalLoad {
    height: 100%;
    overflow-y: visible;
  }
}

.input-barcode {
  position: absolute;
  z-index: 3;
  right: 24px;
  top: 32px;
}

.preloader-scan {
  top: 65px;
  position: relative;
  left: 0;
  right: 0;
  display: table;
  margin: 0 auto;
  height: 100%;
  text-align: center;
}

#left {
  position: relative;
  left: 0px;
}

.owner-name {
  width: 100%;
}

.fee-value {
  position: relative;
  display: flex;
  align-items: center;
}

.bold {
  font-weight: bold;
}
</style>
